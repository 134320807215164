define("survey/models/project-address", ["exports", "survey/utils/generated-model"], function (_exports, _generatedModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = (0, _generatedModel.default)('project-address').reopen({
    state_name: Ember.computed('state.abbr', 'state_text', function () {
      if (Ember.isPresent(this.get('state.abbr'))) {
        return this.get('state.abbr');
      }

      return this.get('state_text');
    }),
    summary: Ember.computed('name', 'city', 'state_name', 'country.name', function () {
      var name = this.get('name');
      var parts = Ember.A();

      if (Ember.isPresent(this.get('city'))) {
        parts.push(this.get('city'));
      }

      if (Ember.isPresent(this.get('state_name'))) {
        parts.push(this.get('state_name'));
      }

      if (Ember.isPresent(this.get('country.name'))) {
        parts.push(this.get('country.name'));
      }

      if (Ember.isPresent(name) && Ember.isPresent(parts)) {
        return "".concat(name, " - ").concat(parts.join(', '));
      }

      if (Ember.isPresent(name)) {
        return name;
      }

      if (Ember.isPresent(parts)) {
        return parts.join(', ');
      }

      return "Local Pickup - Missing Information";
    }),
    detail: Ember.computed('name', 'address_1', 'address_2', 'city', 'state_name', 'postal_code', 'country', function () {
      var _this = this;

      var _map = ['name', 'address_1', 'address_2', 'city', 'state_name', 'postal_code', 'country.name'].map(function (property) {
        if (Ember.isPresent(_this.get(property))) {
          return Ember.Handlebars.Utils.escapeExpression(_this.get(property));
        }
      }),
          _map2 = _slicedToArray(_map, 7),
          name = _map2[0],
          address1 = _map2[1],
          address2 = _map2[2],
          city = _map2[3],
          state_name = _map2[4],
          zip = _map2[5],
          countryName = _map2[6];

      var cityState = [city, state_name].filter(function (x) {
        return Ember.isPresent(x);
      }).join(', ');
      var cityStateZip = [cityState, zip].filter(function (x) {
        return Ember.isPresent(x);
      }).join(' ');
      var lines = [];

      if (Ember.isPresent(name)) {
        lines.push("<b>".concat(name, "</b>"));
      }

      [address1, address2, cityStateZip, countryName].forEach(function (line) {
        if (Ember.isPresent(line)) {
          lines.push(line);
        }
      });

      if (lines.length > 0) {
        return Ember.String.htmlSafe(lines.join('<br>'));
      }

      return "Local Pickup - Missing Information";
    }),
    is_usa: Ember.computed.equal('country.iso3', 'USA'),
    is_canada: Ember.computed.equal('country.iso3', 'CAN'),
    is_international: Ember.computed('country.iso3', 'project.country.iso3', function () {
      if ((this.get('country.iso3') || "").trim().toLowerCase() === (this.get('project.country.iso3') || "").trim().toLowerCase()) {
        // If country is blank or USA, assume domestic
        return false;
      } else {
        // Everything else must be international
        return true;
      }
    })
  });

  _exports.default = _default;
});