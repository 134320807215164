define("survey/pods/application/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "m0M/k836",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[1,[22,\"head-layout\"],false],[0,\"\\n\"],[1,[28,\"page-title\",[\"Crowd Ox\"],[[\"prepend\"],[true]]],false],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[4,\"ss-dimmer\",null,[[\"class\",\"isActive\"],[\"modals page\",[28,\"gt\",[[24,[\"modals\",\"length\"]],0],null]]],{\"statements\":[[4,\"each\",[[24,[\"modals\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"component\",[[23,1,[\"name\"]]],[[\"options\"],[[23,1,[\"options\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "survey/pods/application/template.hbs"
    }
  });

  _exports.default = _default;
});