define("survey/initializers/extend-models", ["exports", "survey/mixins/models/customer", "survey/mixins/models/company", "survey/mixins/models/order", "survey/mixins/models/order/transaction", "survey/mixins/models/product/mapping", "survey/mixins/models/product/variant-value", "survey/mixins/models/project", "survey/mixins/models/project/configuration"], function (_exports, _customer, _company, _order, _transaction, _mapping, _variantValue, _project, _configuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  // Mixins
  function
    /* application */
  initialize() {
    // Models - We have to late load these so they generate properly first
    window.require('survey/models/customer')['default'].reopen(_customer.default); // eslint-disable-line no-undef


    window.require('survey/models/company')['default'].reopen(_company.default); // eslint-disable-line no-undef


    window.require('survey/models/order')['default'].reopen(_order.default); // eslint-disable-line no-undef


    window.require('survey/models/order-transaction')['default'].reopen(_transaction.default); // eslint-disable-line no-undef


    window.require('survey/models/product-mapping')['default'].reopen(_mapping.default); // eslint-disable-line no-undef


    window.require('survey/models/product-variant-value')['default'].reopen(_variantValue.default); // eslint-disable-line no-undef


    window.require('survey/models/project')['default'].reopen(_project.default); // eslint-disable-line no-undef


    window.require('survey/models/project-configuration')['default'].reopen(_configuration.default); // eslint-disable-line no-undef

  }

  var _default = {
    name: 'extend-models',
    initialize: initialize,
    after: ['generate-models', 'ember-data']
  };
  _exports.default = _default;
});