define("survey/pods/project/components/order-selection-extension/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QttcUW1C",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui mini basic buttons\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"can_edit\"]]],null,{\"statements\":[[0,\"    \"],[5,\"link-to\",[[12,\"class\",\"ui button\"]],[[\"@route\",\"@model\"],[\"project.selection\",[24,[\"order_selection\",\"id\"]]]],{\"statements\":[[0,\"\\n      Edit\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "survey/pods/project/components/order-selection-extension/template.hbs"
    }
  });

  _exports.default = _default;
});