define("survey/mixins/ss-accordion-base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    accordionTitleName: 'ss-accordion-title',
    accordionContentName: 'ss-accordion-content',
    isActive: false,
    // Only set externally
    isOpen: false,
    title: null,
    content: null,
    duration: Ember.computed('group.duration', function () {
      var group = this.get('group');

      if (Ember.isPresent(group)) {
        return group.get('duration');
      }

      return 500;
    }),
    transitionMode: Ember.computed('group.transitionMode', function () {
      var group = this.get('group');

      if (Ember.isPresent(group)) {
        return group.get('transitionMode');
      }

      return 'fade';
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.get('isOpen')) {
        this.perform();
      }
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      if (this.get('isOpen') === this.get('isActive')) {
        return;
      }

      this.perform();
    },
    // The method called from accordion and group
    toggle: function toggle() {
      this.toggleProperty('isActive');
      this.get('content').toggle();
    },
    // Called on init
    registerTitle: function registerTitle(title) {
      this.set('title', title);
    },
    registerContent: function registerContent(content) {
      this.set('content', content);
    }
  });

  _exports.default = _default;
});