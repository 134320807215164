define("survey/initializers/ember-data-extension", ["exports", "shared/initializers/ember-data-extension"], function (_exports, _emberDataExtension) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _emberDataExtension.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function get() {
      return _emberDataExtension.initialize;
    }
  });
});