define("survey/pods/project/components/wave-shipping-extension/component", ["exports", "shared/helpers/can-access-feature"], function (_exports, _canAccessFeature) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    order: null,
    wave_shipping_expanded: false,
    can_access: Ember.computed('order.{wave_shipping_selection,show_wave_shipping_selection,project}', function () {
      if (Ember.isBlank(this.get('order.wave_shipping_selection'))) {
        return false;
      } // just let 7th continent through, always shown


      if ((0, _canAccessFeature.canAccessFeature)([this.get('order.project'), 'wave-shipping-7th'])) {
        if (['single', 'multiple'].includes(this.get('order.wave_shipping_selection'))) {
          return true;
        }
      } // if only show wave shipping if selection is single or wave


      if ((0, _canAccessFeature.canAccessFeature)([this.get('order.project'), 'wave-shipping'])) {
        if (this.get('order.show_wave_shipping_selection')) {
          return true;
        }
      }

      return false;
    }),
    ship_text: Ember.computed('order.project.features.[]', function () {
      var wave_shipping = this.get('order.project.features').findBy('name', 'wave-shipping');

      if (Ember.isPresent(wave_shipping) && wave_shipping.settings['wave_shipments'] === "three") {
        return "in up to three";
      }

      return "in two";
    }),
    actions: {
      toggle: function toggle(type) {
        this.toggleProperty("".concat(type, "_expanded"));
        return false;
      }
    }
  });

  _exports.default = _default;
});