define("survey/models/product-zone", ["exports", "shared/utils/monetize", "survey/utils/generated-model", "survey/mixins/zone"], function (_exports, _monetize, _generatedModel, _zone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _generatedModel.default)('product-zone').extend(_zone.default, {
    currency: Ember.computed.readOnly('project.currency'),
    shipping: (0, _monetize.default)('shipping_cents', 'currency'),
    is_shipping_product_based: Ember.computed.equal('cost_type', 'shipping_product_based'),
    is_extra_cap: Ember.computed.equal('cost_type', 'extra_cap')
  });

  _exports.default = _default;
});