define("survey/initializers/load-intl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize(application) {
    if (!window.Intl) {
      application.deferReadiness();
      return new Ember.RSVP.Promise(function (resolve) {
        if (!window.Intl) {
          var el = document.createElement('script');
          el.src = '//cdn.polyfill.io/v2/polyfill.js?features=Intl.~locale.en-US|always';
          el.onload = resolve;
          document.body.appendChild(el);
        } else {
          resolve();
        }
      }).then(function () {
        return application.advanceReadiness();
      });
    }
  }

  var _default = {
    name: 'load-intl',
    initialize: initialize
  };
  _exports.default = _default;
});