define("survey/pods/project/rewards/components/product-information/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['column', 'product-information', 'component'],
    classNameBindings: ['show_small:small'],
    has_quantity: Ember.computed.gt('quantity', 1),
    show_small: Ember.computed.gt('total_selections', 2)
  });

  _exports.default = _default;
});