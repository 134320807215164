define("survey/models/project", ["exports", "ember-data-model-fragments/attributes", "survey/utils/monetize", "survey/utils/generated-model", "shared/utils/image"], function (_exports, _attributes, _monetize, _generatedModel, _image) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _generatedModel.default)('project').extend({
    theme: (0, _attributes.fragment)('project-theme', {
      defaultValue: {}
    }),
    intl: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments); // We have to do this since the service doesn't exist in shared


      var config = Ember.getOwner(this).resolveRegistration('config:environment');
      this.set('config', config);
    },
    // Service
    config: null,
    image_src: (0, _image.default)('image_path'),
    stripe: Ember.computed('payment_gateways.@each.{provider,state}', function () {
      return this.get('payment_gateways').filter(function (item) {
        return item.get('provider') === 'stripe' && item.get('state') === 'connected';
      }).objectAt(0);
    }),
    stripe_has_error: Ember.computed('payment_gateways.@each.{provider,state}', function () {
      if (this.get('payment_gateways').filter(function (item) {
        return item.get('provider') === 'stripe' && (item.get('state') === 'expired' || item.get('state') === 'nonchargeable' || item.get('state') === 'not_working');
      }).length) {
        return true;
      }

      return false;
    }),
    is_stripe_connected: Ember.computed.notEmpty('stripe'),
    no_payment_providers_connected: Ember.computed('is_stripe_connected', 'stripe_has_error', function () {
      return this.get('is_stripe_connected') === false && this.get('stripe_has_error') == false;
    }),
    late_order_url: Ember.computed('slug', function () {
      return "".concat(this.get('config.urls.preorder'), "/projects/").concat(this.get('slug'));
    }),
    creator_url_name: Ember.computed('slug', function () {
      return this.get('slug').split('/')[0];
    }),
    project_url_name: Ember.computed('slug', function () {
      return this.get('slug').split('/')[1];
    }),
    support_email: Ember.computed('identifier', 'support.email', function () {
      if (Ember.isPresent(this.get('support.email'))) {
        return this.get('support.email');
      }

      return "".concat(this.get('identifier'), "@support.crowdox.com");
    }),
    support_name: Ember.computed('name', 'support.dedicated', function () {
      if (this.get('support.dedicated')) {
        return 'Crowd Ox';
      }

      return this.get('name');
    }),
    shipping_is_price_based: Ember.computed.equal('shipping_type', 'product_price'),
    shipping_is_weight_based: Ember.computed.equal('shipping_type', 'product_weight'),
    address_name_is_single: Ember.computed.equal('address_name_type', 'single_name'),
    address_name_is_split_full: Ember.computed.equal('address_name_type', 'split_name'),
    address_name_is_split_initial: Ember.computed.equal('address_name_type', 'split_name_initial'),
    address_name_is_split: Ember.computed.or('address_name_is_split_full', 'address_name_is_split_initial'),
    shipping_weight_based_zones: Ember.computed.filterBy('zones', 'is_shipping_weight_based', true),
    sales_tax_zones: Ember.computed.filterBy('zones', 'is_sales_tax', true),
    vat_zones: Ember.computed.filterBy('zones', 'is_vat', true),
    customs_zones: Ember.computed.filterBy('zones', 'is_customs', true),
    shipping_weight_based_zone_locations: Ember.computed.filterBy('zone_locations', 'is_shipping_weight_based', true),
    sales_tax_zone_locations: Ember.computed.filterBy('zone_locations', 'is_sales_tax', true),
    vat_zone_locations: Ember.computed.filterBy('zone_locations', 'is_vat', true),
    customs_zone_locations: Ember.computed.filterBy('zone_locations', 'is_customs', true),
    currency_base: Ember.computed('currency', function () {
      var currency = this.get('currency');
      var subunit_conversion = (0, _monetize.subunit_to_unit)(currency);
      var place = (0, _monetize.get_place_from_subunit)(subunit_conversion);
      return place;
    }),
    zero_decimal_currency: Ember.computed('currency', function () {
      var currency = this.get('currency');
      var subunit_conversion = (0, _monetize.subunit_to_unit)(currency);
      var place = (0, _monetize.get_place_from_subunit)(subunit_conversion);

      if (place !== 100) {
        return true;
      }

      return false;
    }),
    decimal_currency: Ember.computed.not('zero_decimal_currency'),
    t: Ember.computed('purpose', 'intl.primaryLocale', 'translations.@each.{locale,rendered}', {
      get: function get() {
        var translations = this.get('translations');

        if (!translations) {
          return null;
        }

        var locale = this.get('intl.primaryLocale');
        var translation;
        translation = translations.findBy('locale', locale.split('-')[0]);

        if (translation) {
          return translation;
        }

        translation = translations.findBy('locale', locale);

        if (translation) {
          return translation;
        }

        return null;
      }
    })
  });

  _exports.default = _default;
});