define("survey/formats", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    time: {
      default: {
        hour: '2-digit',
        minute: '2-digit'
      }
    },
    date: {
      short: {
        month: 'short',
        day: '2-digit',
        year: 'numeric'
      },
      shortWithTime: {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      }
    },
    number: {
      currency: {
        style: 'currency',
        minimumFractionDigits: 0
      }
    }
  };
  _exports.default = _default;
});