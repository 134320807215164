define("survey/models/product-mapping", ["exports", "shared/utils/monetize", "survey/utils/generated-model"], function (_exports, _monetize, _generatedModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _generatedModel.default)('product-mapping').extend({
    currency: Ember.computed.readOnly('project.currency'),
    additional_price: (0, _monetize.default)('additional_price_cents', 'currency'),
    has_quantity: Ember.computed.gt('required_quantity', 1),
    product_title: Ember.computed('child.name', 'additional_price', 'project.currency_symbol', function () {
      if (Ember.isBlank(this.get('additional_price')) || this.get('additional_price') <= 0) {
        return this.get('child.name');
      }

      return "".concat(this.get('child.name'), " (+").concat(this.get('project.currency_symbol')).concat(this.get('additional_price'), ")");
    })
  });

  _exports.default = _default;
});