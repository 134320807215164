define("survey/services/analytics", ["exports", "survey/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var google_analytics_id_path = 'settings.analytics.google.id';
  var facebook_analytics_id_path = 'settings.analytics.facebook.id';
  var Analytics = Ember.Mixin.create({
    initAnalytics: function initAnalytics(order) {
      if (Ember.isBlank(order)) {
        return false;
      }

      this.facebookInit(order);
      this.googleInit(order);
    },
    facebookInit: function facebookInit(order) {
      var pixel_ids = this.getFacebookPixelIds(order.get('project'));
      var params = {
        uid: order.id // email: order.get('customer.email')

      };

      var _iterator = _createForOfIteratorHelper(pixel_ids),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var pixel_id = _step.value;
          window.fbq('init', pixel_id, params);
          window.fbq('setUserProperties', pixel_id, {
            company: order.get('project.company.name'),
            // Company name
            project: order.get('project.name'),
            // Project name
            source: order.get('source.site'),
            // Source site name
            project_id: order.get('project.id'),
            // Project Id
            category: order.get('project.category_name'),
            // Category
            child_category: order.get('project.child_category_name') || '' // Sub Category

          });
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    },
    googleInit: function googleInit(order) {
      var router = Ember.getOwner(this).get('router');

      if (Ember.isPresent(router)) {
        if (Ember.isPresent(order.get('project')) && Ember.isPresent(order.get('project').get(google_analytics_id_path))) {
          router.set('hasTracker', true);
        }
      }

      return this.doGoogleTrackers(order.get('project'), function (tracker) {
        var params = {
          'allowLinker': true,
          'userId': order.get('id')
        };

        if (Ember.isPresent(tracker.name)) {
          params['name'] = tracker.name;
        }

        window.ga('create', tracker.id, 'auto', params); // Initialize the ecommerce plugin

        window.ga("".concat(tracker.namePrefix, "require"), 'ecommerce');

        if (Ember.isPresent(order.get('project'))) {
          window.ga("".concat(tracker.namePrefix, "set"), 'dimension1', order.get('project.company.name')); // Company name

          window.ga("".concat(tracker.namePrefix, "set"), 'dimension2', order.get('project.name')); // Project name

          window.ga("".concat(tracker.namePrefix, "set"), 'dimension3', order.get('source.site')); // Source site name

          window.ga("".concat(tracker.namePrefix, "set"), 'dimension4', order.get('project.id')); // Project Id

          window.ga("".concat(tracker.namePrefix, "set"), 'dimension5', order.get('project.category_name')); // Category

          window.ga("".concat(tracker.namePrefix, "set"), 'dimension6', order.get('project.child_category_name') || ''); // Sub Category
        }
      });
    },
    pageEvent: function pageEvent(order, page) {
      if (Ember.isBlank(order)) {
        return false;
      }

      this.facebookViewContent(order, page);
      this.googleEvent(order, page);
    },
    facebookViewContent: function facebookViewContent(order, page) {
      // Don't need to track individual pixels because it fires for both without passing in a pixel id specifically
      var pixel_ids = this.getFacebookPixelIds(order.get('project'));

      if (pixel_ids.length === 0) {
        return false;
      }

      window.fbq('track', 'ViewContent', {
        page: page
      });
    },
    googleEvent: function googleEvent(order, event) {
      return this.doGoogleTrackers(order.get('project'), function (tracker) {
        window.ga("".concat(tracker.namePrefix, "send"), {
          hitType: 'event',
          eventCategory: "".concat(order.get('project.name'), ": ").concat(order.get('source.friendly_name')),
          eventAction: event
        });
      });
    },
    purchaseEvent: function purchaseEvent(order, transaction_id, balance) {
      if (Ember.isBlank(order)) {
        return false;
      }

      this.facebookPurchase(order, transaction_id, balance);
      this.googlePurchase(order, transaction_id, balance);
    },
    facebookPurchase: function facebookPurchase(order, transaction_id, balance) {
      // Don't need to track individual pixels because it fires for both without passing in a pixel id specifically
      var pixel_ids = this.getFacebookPixelIds(order.get('project'));

      if (pixel_ids.length === 0) {
        return false;
      }

      window.fbq('track', 'Purchase', {
        value: balance,
        currency: order.get('project.currency'),
        transaction_id: transaction_id
      });
    },
    googlePurchase: function googlePurchase(order, transaction_id, balance) {
      this.googleEvent(order, 'purchase');
      return this.doGoogleTrackers(order.get('project'), function (tracker) {
        // Send a global event about the project, order and transaction
        window.ga("".concat(tracker.namePrefix, "ecommerce:addTransaction"), {
          'id': transaction_id,
          'affiliation': order.get('project.name'),
          'revenue': balance,
          'currency': order.get('project.currency')
        });
        window.ga("".concat(tracker.namePrefix, "ecommerce:send"));
      });
    },
    doGoogleTrackers: function doGoogleTrackers(project, callback) {
      var google_analytics_id = Ember.get(_environment.default, 'googleAnalytics.webPropertyId');

      if (google_analytics_id == null) {
        return false;
      }

      var trackers = [{
        id: google_analytics_id
      }];

      if (Ember.isPresent(project) && Ember.isPresent(project.get(google_analytics_id_path))) {
        trackers.push({
          id: project.get(google_analytics_id_path),
          // If you change this, you must update analytics.js mixin
          name: 'projectTracker'
        });
      }

      for (var _i = 0, _trackers = trackers; _i < _trackers.length; _i++) {
        var tracker = _trackers[_i];
        tracker.namePrefix = '';

        if (Ember.isPresent(tracker.name)) {
          tracker.namePrefix = tracker.name + '.';
        }

        callback(tracker);
      }

      return true;
    },
    getFacebookPixelIds: function getFacebookPixelIds(project) {
      var pixel_ids = [];
      var facebook_pixel_id = Ember.get(_environment.default, 'facebookAnalytics.pixelId');

      if (facebook_pixel_id != null) {
        pixel_ids.push(facebook_pixel_id);
      }

      if (Ember.isPresent(project) && Ember.isPresent(project.get(facebook_analytics_id_path))) {
        pixel_ids.push(project.get(facebook_analytics_id_path));
      }

      return pixel_ids;
    }
  });

  var _default = Ember.Service.extend(Analytics, {
    initialize: function initialize(order) {
      return this.initAnalytics(order);
    }
  });

  _exports.default = _default;
});