define("survey/templates/components/ss-accordion-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MAE6j4v6",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"accordion\"],[[28,\"component\",[[24,[\"accordionComponentName\"]]],[[\"group\"],[[23,0,[]]]]]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "survey/templates/components/ss-accordion-group.hbs"
    }
  });

  _exports.default = _default;
});