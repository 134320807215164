define("survey/pods/project/errors/not-logged-in-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yGJ4mOwB",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n  You Are No Longer Logged In\\n  \"],[7,\"div\",false],[12,\"class\",\"ui close button\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"\\n    Oh no! It appears you are no longer logged in and your changes weren't saved.\\n    To finish your selections please take the following actions:\\n  \"],[9],[0,\"\\n  \"],[7,\"ol\",true],[10,\"class\",\"ui list\"],[8],[0,\"\\n    \"],[7,\"li\",true],[8],[0,\"Close this window\"],[9],[0,\"\\n    \"],[7,\"li\",true],[8],[0,\"Find the confirmation email from \"],[7,\"b\",true],[8],[1,[24,[\"project\",\"name\"]],false],[9],[9],[0,\"\\n    \"],[7,\"li\",true],[8],[0,\"Click the link and complete your selections\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "survey/pods/project/errors/not-logged-in-modal/template.hbs"
    }
  });

  _exports.default = _default;
});