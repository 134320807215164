define("survey/models/order-selection-question", ["exports", "ember-cp-validations", "survey/utils/generated-model"], function (_exports, _emberCpValidations, _generatedModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var validations = (0, _emberCpValidations.buildValidations)({
    project: {
      description: "Project",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    order: {
      description: "Order",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    selection: {
      description: "Order Selection",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    product: {
      description: "Product",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    product_question: {
      description: "Product Question",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    product_question_choice: {
      description: "Product Question Choice",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', {
        // if its not a question, its required, if it is it should be blank
        presence: Ember.computed.readOnly('model.product_question.is_multiple'),
        // If its not required, disable validation
        disabled: Ember.computed.not('model.product_question.required')
      })]
    },
    text: {
      description: "Text",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', {
        // if its not a question, its required, if it is it should be blank
        presence: Ember.computed.readOnly('model.product_question.is_text'),
        // If its not required, disable validation
        disabled: Ember.computed.not('model.product_question.required')
      }), (0, _emberCpValidations.validator)('length', {
        allowBlank: true,
        min: Ember.computed.readOnly('model.product_question.min_length'),
        disabled: Ember.computed('model.product_question.min_length', 'model.text', function () {
          return Ember.isBlank(this.get('model.product_question.min_length')) || Ember.isBlank(this.get('model.text'));
        })
      }), (0, _emberCpValidations.validator)('length', {
        allowBlank: true,
        max: Ember.computed.readOnly('model.product_question.max_length'),
        disabled: Ember.computed('model.product_question.max_length', 'model.text', function () {
          return Ember.isBlank(this.get('model.product_question.max_length')) || Ember.isBlank(this.get('model.text'));
        })
      })]
    },
    quantity: {
      description: "Quantity",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', {
        // if its not a text question, its required, if it is it should be blank
        presence: Ember.computed.readOnly('model.product_question.is_multiple')
      }), (0, _emberCpValidations.validator)('number', {
        integer: true,
        gte: 1,
        disabled: Ember.computed.readOnly('model.product_question.is_text')
      })]
    }
  });

  var _default = (0, _generatedModel.default)('order-selection-question').extend(validations);

  _exports.default = _default;
});