define("survey/components/share-tweet/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    twitter_handles: Ember.computed('project.twitter_handle', function () {
      var handles = "@crowdoxapp";

      if (!Ember.isBlank(this.get('project.twitter_handle'))) {
        handles = "@".concat(this.get('project.twitter_handle'), " ").concat(handles);
      }

      return handles;
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var shareScript = "<script>!function(d,s,id){var js,fjs=d.getElementsByTagName(s)[0],p=/^http:/.test(d.location)?'http':'https';if(!d.getElementById(id)){js=d.createElement(s);js.id=id;js.src=p+'://platform.twitter.com/widgets.js';fjs.parentNode.insertBefore(js,fjs);}}(document, 'script', 'twitter-wjs');</script>";
      return (0, _jquery.default)(shareScript).appendTo((0, _jquery.default)(this.element));
    }
  });

  _exports.default = _default;
});