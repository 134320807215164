define("survey/pods/project/shipping/no_shipping/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DpuIJDyq",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\",true],[10,\"class\",\"ui navigation header\"],[8],[0,\"\\n  Shipping\\n  \"],[7,\"div\",true],[10,\"class\",\"sub header\"],[8],[0,\"\\n    Looks like you are good to go.\\n    We don't need any shipping information for your current selections.\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"actions centered\"],[8],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",[29,[\"ui huge green button \",[28,\"if\",[[24,[\"orderTasks\",\"save\",\"isRunning\"]],\"disabled\",\"continue\"],null]]]],[3,\"action\",[[23,0,[]],\"next_with_step\"]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"orderTasks\",\"save\",\"isRunning\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"ui mini active inline loader\"],[8],[9],[0,\" Saving\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      Continue to Confirm \"],[7,\"i\",true],[10,\"class\",\"angle right icon\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[4,\"unless\",[[24,[\"orderTasks\",\"save\",\"isRunning\"]]],null,{\"statements\":[[0,\"    \"],[5,\"link-to\",[[12,\"class\",\"ui sub text go back\"]],[[\"@route\"],[\"project.extras\"]],{\"statements\":[[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"angle left icon\"],[8],[9],[0,\" Back to \"],[1,[28,\"t-stage\",[[24,[\"project\"]],\"checkout.extras.header\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "survey/pods/project/shipping/no_shipping/template.hbs"
    }
  });

  _exports.default = _default;
});