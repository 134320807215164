define("survey/components/address-display/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['address', 'display'],
    project: Ember.computed.readOnly('address.project')
  });

  _exports.default = _default;
});