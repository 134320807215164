define("survey/pods/confirm/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['token', 'amp;token', 'order_id', 'step', 'auto']
  });

  _exports.default = _default;
});