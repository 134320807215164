define("survey/pods/project/rewards/controller", ["exports", "survey/mixins/determine-error"], function (_exports, _determineError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_determineError.default, {
    queryParams: ['auto'],
    // The query param comes in, which sets auto confirm
    // Need the two steps, so on going back it doesn't reset the query param
    auto: false,
    // Set in route setupController from auto. Only happens 1st time
    auto_complete: false,
    analytics: Ember.inject.service(),
    order: null,
    startingConfiguration: null,
    project: Ember.computed.readOnly('order.project'),
    project_source: Ember.computed.readOnly('order.source'),
    reward_line: Ember.computed.readOnly('order.reward_lines.firstObject'),
    any_configuration_locked: Ember.computed('reward_line.selections.@each.is_locked', function () {
      return this.get('reward_line.selections').isAny('is_locked');
    }),
    all_configurations_locked: Ember.computed('reward_line.selections.@each.is_locked', function () {
      return this.get('reward_line.selections').isEvery('is_locked');
    }),
    upgrade_configurations: Ember.computed('order.source.configurations.@each.{id,is_live,is_complete,can_select,product_reward,upgrade_type}', 'order.{is_internal,is_test}', 'order.original_configuration.{id,product_reward.price}', 'startingConfiguration.id', 'reward_line.product_bundle.id', 'order.original_configuration.upgrade_type', function () {
      var _this = this;

      return this.get('order.source.configurations').filter(function (configuration) {
        // This logic should mostly match backend OrderActions::Can.change_or_upgrade_to
        if (configuration.get('is_complete')) {
          return false;
        } // If the configuration is in setup and its not a test, can't do anything to it.


        if (configuration.get('in_setup') && _this.get('order.is_test') !== true) {
          return false;
        } // Don't show the starting configuration this order had when we first came in.
        // That one is already displayed above these choices.


        if (configuration.get('id') === _this.get('startingConfiguration.id')) {
          return false;
        } // I don't think this is needed anymore, current configuration should be the master reference at the moment
        // // If the reward line matches then its an upgradable choice
        // // Either previously selected or set by the project creator
        // if (configuration.get('product_reward.id') === this.get('reward_line.product_bundle.id')) {
        //   return true;
        // }
        // If you can't select, return


        if (configuration.get('can_select') === false) {
          return false;
        } // If the original configuration's upgrade_type is none then we don't allow upgrades


        if (_this.get('order.original_configuration.upgrade_type') === 'none') {
          return false;
        } // If upgrade type is set to any, just check if they can upgrade without checking price


        if (_this.get('order.original_configuration.upgrade_type') === 'any') {
          return true;
        }

        if (_this.get('order.original_configuration.upgrade_type') === 'equal_or_higher') {
          if (configuration.get('product_reward.price') >= _this.get('order.original_configuration.product_reward.price')) {
            return true;
          }

          return false;
        }

        return false;
      });
    }),
    upgrade_configurations_sort: ['product_reward.price_cents', 'product_reward.name'],
    upgrade_configurations_sorted: Ember.computed.sort('upgrade_configurations', 'upgrade_configurations_sort'),
    has_configurations_to_upgrade_to: Ember.computed('upgrade_configurations.@each.{is_all_gone,product_reward}', 'reward_line.product_bundle.id', function () {
      var _this2 = this;

      return this.get('upgrade_configurations').any(function (configuration) {
        // if the configuration is all gone and the reward line isn't a match, not an upgrade
        if (configuration.get('is_all_gone') && configuration.get('product_reward.id') !== _this2.get('reward_line.product_bundle.id')) {
          return false;
        } // We have something to upgrade too


        return true;
      });
    }),
    actions: {
      next: function next(step) {
        if (Ember.isBlank(step)) {
          step = 'project.extras';
        }

        this.get('analytics').pageEvent(this.get('order'), 'rewards');
        this.transitionToRoute(step);
      }
    }
  });

  _exports.default = _default;
});