define("survey/pods/project/errors/address-invalid-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Mur0Ql6T",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n  Invalid shipping address\\n  \"],[7,\"div\",false],[12,\"class\",\"ui close button\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"\\n    Sorry, but the shipping address you have provided is invalid.\\n  \"],[9],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"\\n    Please edit your shipping address and try again.\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "survey/pods/project/errors/address-invalid-modal/template.hbs"
    }
  });

  _exports.default = _default;
});