define("survey/components/order-summary-totals/component", ["exports", "survey/components/order-summary-totals/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['ui', 'vertically', 'divided', 'grid', 'order-summary-totals', 'component'],
    project: Ember.computed.readOnly('order.project'),
    total_text: "Balance",
    // This can't be read only so we can replace it for wave shipping
    calculated_balance: Ember.computed('order.balance', {
      get: function get() {
        if (this._calculated_balance) {
          return this._calculated_balance;
        } // We have to use this instead of readonly for 7th


        return this.get('order.balance');
      },
      set: function set(key, value) {
        return this._calculated_balance = value;
      }
    }),
    extra_funds_amount: Ember.computed.readOnly('order.extra_funds_amount'),
    has_extra_funds: Ember.computed.lt('calculated_balance', 0),
    reward_line: Ember.computed.readOnly('order.reward_lines.firstObject'),
    // Flags for displaying information
    hide_shipping: false,
    hide_totals: false
  });

  _exports.default = _default;
});