define("survey/models/payment", ["exports", "@ember-data/model", "survey/mixins/stripe-charge"], function (_exports, _model, _stripeCharge) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_stripeCharge.default, {
    transaction: (0, _model.belongsTo)('order-transaction', {
      inverse: null,
      async: false
    }),
    order: (0, _model.belongsTo)('order', {
      inverse: null,
      async: false
    }),
    project: Ember.computed.alias('order.project'),
    provider: Ember.computed('payment_gateway.provider', function () {
      if (this.get('payment_gateway.provider') === 'stripe') {
        return 'Stripe';
      }

      return null;
    }),
    deleteRecord: function deleteRecord() {
      if (this.get('transaction')) {
        this.get('transaction').deleteRecord();
      }

      return this._super.apply(this, arguments);
    },
    save: function save() {
      return this.get('transaction').save();
    },
    charge: function charge(amount_cents, shipping_amount_cents) {
      return this["".concat(this.get('provider').toLowerCase(), "_charge")](amount_cents, shipping_amount_cents);
    },
    initializeTransaction: function initializeTransaction(_ref) {
      var amount_cents = _ref.amount_cents,
          shipping_amount_cents = _ref.shipping_amount_cents;

      if (Ember.isPresent(this.get('transaction'))) {
        this.get('transaction').deleteRecord();
      }

      var transaction = this.store.createRecord('order-transaction', {
        confirmed: false,
        provider: this.get('provider'),
        order: this.get('order'),
        project: this.get('project'),
        payment_gateway: this.get('payment_gateway'),
        amount_cents: amount_cents,
        currency: this.get('project.currency'),
        shipping_amount_cents: shipping_amount_cents
      });
      this.set('transaction', transaction);
    }
  });

  _exports.default = _default;
});