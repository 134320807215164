define("survey/pods/project/shipping/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y8eCQ5Br",
    "block": "{\"symbols\":[\"actions\"],\"statements\":[[4,\"if\",[[24,[\"order\",\"local_pickup_address\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"page-title\",[\"Local Pickup Address\"],null],false],[0,\"\\n  \"],[15,\"project/shipping/local_pickup\",[]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"page-title\",[[28,\"t-stage\",[[24,[\"project\"]],\"checkout.address.navigation\"],null]],null],false],[0,\"\\n\"],[4,\"project/components/address-workflow\",null,[[\"order\",\"next\",\"check_error\",\"determine_error\"],[[24,[\"order\"]],[28,\"action\",[[23,0,[]],\"next\"],null],[28,\"action\",[[23,0,[]],\"check_error\"],null],[28,\"action\",[[23,0,[]],\"determine_error\"],null]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"order\",\"requires_shipping\"]]],null,{\"statements\":[[0,\"      \"],[15,\"project/shipping/edit\",[1]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[15,\"project/shipping/no_shipping\",[1]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"parameters\":[]}]],\"hasEval\":true}",
    "meta": {
      "moduleName": "survey/pods/project/shipping/template.hbs"
    }
  });

  _exports.default = _default;
});