define("survey/components/ss-accordion-title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['title'],
    classNameBindings: ['accordion.isActive:active'],
    onclick: function onclick() {},
    init: function init() {
      this._super.apply(this, arguments);

      this.get('accordion').registerTitle(this);
    },
    click: function click() {
      this.get('accordion').perform();
      this.get('onclick')();
    }
  });

  _exports.default = _default;
});