define("survey/pods/project/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8AYt5BSW",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[[24,[\"project\",\"name\"]]],null],false],[0,\"\\n\"],[4,\"if\",[[24,[\"project\",\"theme\",\"custom_css\"]]],null,{\"statements\":[[0,\"  \"],[7,\"style\",true],[8],[1,[24,[\"project\",\"theme\",\"custom_css\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"content dimmable outlet\"],[8],[0,\"\\n\"],[0,\"  \"],[7,\"div\",true],[10,\"class\",\"ui hidden inverted dimmer\"],[10,\"id\",\"page-dimmer\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"ui text loader\"],[8],[0,\"Loading\"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"inner layout\"],[8],[0,\"\\n    \"],[1,[22,\"outlet\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"ui basic footer stackable grid outlet\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"eight wide column\"],[8],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"eight wide right aligned column\"],[8],[0,\"\\n      \"],[1,[28,\"t-stage\",[[24,[\"project\"]],\"checkout.phrase.product_questions\"],null],false],[0,\" \"],[7,\"a\",true],[11,\"href\",[29,[\"mailto:\",[24,[\"project\",\"support_email\"]]]]],[8],[1,[28,\"t-stage\",[[24,[\"project\"]],\"checkout.phrase.contact\"],null],false],[0,\" \"],[7,\"em\",true],[8],[1,[24,[\"project\",\"support_name\"]],false],[9],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"cookie row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"sixteen wide column\"],[8],[0,\"\\n      \"],[7,\"a\",true],[10,\"href\",\"https://crowdox.com/legal/cookies\"],[10,\"target\",\"_blank\"],[10,\"rel\",\"noopener noreferrer\"],[8],[1,[28,\"t-stage\",[[24,[\"project\"]],\"checkout.phrase.we_use_cookies\"],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "survey/pods/project/template.hbs"
    }
  });

  _exports.default = _default;
});