define("survey/pods/project/rewards/components/configuration-selections/component", ["exports", "shared/mixins/load-countries", "shared/mixins/address-state-selection"], function (_exports, _loadCountries, _addressStateSelection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_loadCountries.default, _addressStateSelection.default, {
    classNames: ['one', 'column', 'row', 'configuration-selections', 'component'],
    init: function init() {
      this._super.apply(this, arguments);

      var order = this.get('order');
      var order_line = this.get('order_line');
      this.get('loadCountriesTask').perform({
        order_line: order_line,
        order: order
      });
    },
    order: Ember.computed.readOnly('order_line.order'),
    project: Ember.computed.readOnly('order.project'),
    show_local_pickup: Ember.computed('order.local_pickup_address.id', 'order.project.addresses.length', function () {
      if (Ember.isPresent(this.get('order.local_pickup_address.id'))) {
        return true;
      }

      if (this.get('order.project.addresses.length') > 0) {
        return true;
      }

      return false;
    }),
    total_external_selections_with_choices_count: Ember.computed('order.external_lines.@each.total_selections_with_choices_count', function () {
      return this.get('order.external_lines').reduce(function (value, order_line) {
        return value + (order_line.get('total_selections_with_choices_count') || 0);
      }, 0);
    }),
    total_manual_selections_with_choices_count: Ember.computed('order.manual_lines.@each.total_selections_with_choices_count', function () {
      return this.get('order.manual_lines').reduce(function (value, order_line) {
        return value + (order_line.get('total_selections_with_choices_count') || 0);
      }, 0);
    }),
    total_configurations: Ember.computed('order_line.available_mappings_with_choices_sorted.length', 'total_external_selections_with_choices_count', 'total_manual_selections_with_choices_count', function () {
      return this.get('order_line.available_mappings_with_choices_sorted.length') + this.get('total_external_selections_with_choices_count') + this.get('total_manual_selections_with_choices_count');
    }),
    has_configurations: Ember.computed.gt('total_configurations', 0),
    actions: {
      toggle_pickup: function toggle_pickup(value) {
        if (value === false) {
          this.set('order.local_pickup_address', null);
        }

        this.set('order.use_local_pickup', value);
      },
      update_country: function update_country(value) {
        this.set('order.shipping_address.country', value);
        this.get('verifyStateTask').perform(this.get('order.shipping_address'));
      }
    }
  });

  _exports.default = _default;
});