define("survey/components/hero-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ejvqxhaA",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"outlet\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"project\",\"theme\",\"logo_image_src\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"project logo\"],[8],[0,\"\\n      \"],[7,\"img\",true],[11,\"src\",[24,[\"project\",\"theme\",\"logo_image_src\"]]],[10,\"alt\",\"project\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"h1\",true],[10,\"class\",\"ui project header\"],[11,\"style\",[28,\"html-safe\",[\"color: \",[24,[\"project\",\"theme\",\"default_header_text_color\"]],\";\"],null]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"project\",\"theme\",\"project_name_image_src\"]]],null,{\"statements\":[[0,\"      \"],[7,\"img\",true],[11,\"src\",[24,[\"project\",\"theme\",\"project_name_image_src\"]]],[10,\"alt\",\"project theme background\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[24,[\"project\",\"theme\",\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[24,[\"project\",\"theme\",\"tagline\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"sub header\"],[11,\"style\",[28,\"html-safe\",[\"color: \",[24,[\"project\",\"theme\",\"default_header_subtext_color\"]],\";\"],null]],[8],[1,[24,[\"project\",\"theme\",\"tagline\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\\n  \"],[14,1],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "survey/components/hero-header/template.hbs"
    }
  });

  _exports.default = _default;
});