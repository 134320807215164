define("survey/pods/project/review/route", ["exports", "survey/mixins/restrict-by-status", "survey/mixins/scroll-route"], function (_exports, _restrictByStatus, _scrollRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend((0, _restrictByStatus.default)(['completed', 'canceled']), _scrollRoute.default, {
    redirect: function redirect() {
      var order = this.controllerFor('project').get('order'); // The review page can only be viewed by someone who is either
      // - Approved OR
      // - Canceled

      if (Ember.isBlank(order.get('approved_on')) && Ember.isBlank(order.get('canceled_on')) && Ember.isBlank(order.get('refused_on'))) {
        this.replaceWith('project.rewards');
      }
    },
    setupController: function setupController(controller) {
      this._super(controller, null);

      var order = this.controllerFor('project').get('order');
      controller.set('order', order);
    }
  });

  _exports.default = _default;
});