define("survey/pods/project/clients/serious-poulp/wave/menu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LkD4pbr1",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui fluid four item confirmation menu\"],[10,\"data-marker\",\"scroll-top\"],[8],[0,\"\\n  \"],[5,\"link-to\",[[12,\"class\",\"item\"]],[[\"@route\"],[\"project.rewards\"]],{\"statements\":[[0,\" \"],[7,\"span\",true],[10,\"class\",\"circled\"],[8],[7,\"em\",true],[8],[0,\"1\"],[9],[9],[0,\" \"],[7,\"span\",true],[10,\"class\",\"text\"],[8],[0,\" Reward  \"],[9],[0,\" \"]],\"parameters\":[]}],[0,\"\\n  \"],[5,\"link-to\",[[12,\"class\",\"item\"]],[[\"@route\"],[\"project.extras\"]],{\"statements\":[[0,\" \"],[7,\"span\",true],[10,\"class\",\"circled\"],[8],[7,\"em\",true],[8],[0,\"2\"],[9],[9],[0,\" \"],[7,\"span\",true],[10,\"class\",\"text\"],[8],[0,\" Extras  \"],[9],[0,\" \"]],\"parameters\":[]}],[0,\"\\n  \"],[5,\"link-to\",[[12,\"class\",\"item\"]],[[\"@route\",\"@current-when\"],[\"project.shipping\",\"project.clients.serious_poulp.wave\"]],{\"statements\":[[0,\" \"],[7,\"span\",true],[10,\"class\",\"circled\"],[8],[7,\"em\",true],[8],[0,\"3\"],[9],[9],[0,\" \"],[7,\"span\",true],[10,\"class\",\"text\"],[8],[0,\" Shipping \"],[9],[0,\" \"]],\"parameters\":[]}],[0,\"\\n  \"],[5,\"link-to\",[[12,\"class\",\"item\"]],[[\"@route\",\"@disabled\"],[\"project.confirm\",true]],{\"statements\":[[0,\" \"],[7,\"span\",true],[10,\"class\",\"circled\"],[8],[7,\"em\",true],[8],[0,\"4\"],[9],[9],[0,\" \"],[7,\"span\",true],[10,\"class\",\"text\"],[8],[0,\" Confirm  \"],[9],[0,\" \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "survey/pods/project/clients/serious-poulp/wave/menu/template.hbs"
    }
  });

  _exports.default = _default;
});