define("survey/components/ss-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['ui', 'checkbox'],
    classNameBindings: ['checked:checked', 'readonly:read-only'],
    checked: false,
    indeterminate: null,
    click: function click() {
      if (this.get('disabled') || this.get('readonly')) {
        return;
      }

      var action = this.get('onChange');

      if (typeof action === 'function') {
        var checked = !this.get('checked');
        action(checked);
      }
    }
  });

  _exports.default = _default;
});