define("survey/pods/application/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: [// Link Tracking
    '_ga', 'utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']
  });

  _exports.default = _default;
});