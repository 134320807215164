define("survey/mixins/extend-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      open_modal: function open_modal(name) {
        var args = Array.prototype.slice.call(arguments, 1);
        Ember.getOwner(this).lookup('route:application').send('open_modal', name, args.length > 0 ? args[0] : null);
      }
    }
  });

  _exports.default = _default;
});