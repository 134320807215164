define("survey/models/price-data", ["exports", "ember-data-model-fragments/fragment", "ember-data-model-fragments/attributes", "@ember-data/model"], function (_exports, _fragment, _attributes, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fragment.default.extend({
    prices: (0, _attributes.fragmentArray)('price-data-price'),
    reference: (0, _attributes.fragment)('price-data-reference'),
    settings: (0, _attributes.fragment)('price-data-settings'),
    // We can ignore generated for is_empty
    type: (0, _model.attr)('string'),
    live: (0, _model.attr)('boolean', {
      allowNull: true
    }),
    is_default: Ember.computed.equal('type', 'default'),
    is_fixed: Ember.computed.equal('type', 'fixed'),
    is_generated: Ember.computed.equal('type', 'generated'),
    is_empty: Ember.computed('prices.[]', 'reference.is_empty', function () {
      if (this.get('prices.length') !== 0) {
        return false;
      }

      if (this.get('reference') != null) {
        if (this.get('reference.is_empty') !== true) {
          return false;
        }
      }

      return true;
    })
  });

  _exports.default = _default;
});