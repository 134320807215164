define("survey/mixins/restrict-by-step", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(route_step) {
    return Ember.Mixin.create({
      beforeModel: function beforeModel(transition) {
        var _this = this;

        return Ember.RSVP.resolve(this._super.apply(this, arguments)).then(function () {
          // If we are already transitioning to something else, return
          if (transition != null && transition.isAborted) {
            return;
          }

          var order = _this.controllerFor('project').get('order');

          if (order == null) {
            return;
          }

          var next_step = 'rewards';

          if (Ember.isPresent(order.get('furthest_step'))) {
            next_step = order.get('furthest_step');
          }

          next_step = 'project.' + next_step;

          if (!order.can_go_to_step(route_step)) {
            if (transition.targetName === next_step) {
              return;
            }

            _this.replaceWith(next_step);
          }
        });
      }
    });
  }
});