define("survey/pods/project/confirm/credit-card/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    provider: 'Stripe',
    stripeLoadTimedOut: false,
    stripe: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this.stripe.call(function (Stripe) {
        var card = Stripe.elements().create('card');
        card.mount((0, _jquery.default)('#card-element', _this.element)[0]);

        _this.get('payment').initializeCard(card);
      }); // Display the timeout message in 10 seconds if Stripe hasn't initialized
      // in that time.

      this.timeout = setTimeout(function () {
        _this.set('stripeLoadTimedOut', true);
      }, 10000);
    },
    willDestroyElement: function willDestroyElement() {
      clearTimeout(this.timeout);
    },
    submittedChanged: Ember.observer('submitted', 'payment.cardComplete', function () {
      var el = (0, _jquery.default)('#card-element', this.element)[0];
      var submitted = this.get('submitted');
      var complete = this.get('payment.cardComplete');

      if (submitted && !complete) {
        el.classList.add('invalid');
      } else {
        el.classList.remove('invalid');
      }
    })
  });

  _exports.default = _default;
});