define("survey/models/order", ["exports", "shared/mixins/project-total", "shared/mixins/prices", "shared/utils/monetize", "ember-cp-validations", "survey/utils/generated-model", "ember-data-model-fragments/attributes", "big.js", "@ember-data/model"], function (_exports, _projectTotal, _prices, _monetize, _emberCpValidations, _generatedModel, _attributes, _big, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.calculated_object = _exports.MINIMUM_BALANCE_AMOUNT = void 0;
  var validations = (0, _emberCpValidations.buildValidations)({
    project: {
      description: "Project",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    customer: {
      description: "Customer",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    source: {
      description: "Order Source",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    original_configuration: {
      description: "Original Configuration",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    current_configuration: {
      description: "Current Configuration",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    lines: {
      description: "Order Lines",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('has-many'), (0, _emberCpValidations.validator)('presence', true)]
    },
    shipping_address: {
      description: "Shipping Address",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('belongs-to'), (0, _emberCpValidations.validator)('presence', true)]
    },
    local_pickup_address: {
      description: "Local Pickup Address",
      validators: [(0, _emberCpValidations.validator)('ds-error')]
    } // Don't validate Transactions
    // Don't validate Emails

  });
  var MINIMUM_BALANCE_AMOUNT = 0.50;
  _exports.MINIMUM_BALANCE_AMOUNT = MINIMUM_BALANCE_AMOUNT;
  var calculationMixin = Ember.Mixin.create({
    // Balance helpers
    // not used calculations, just for display.
    extra_funds_amount: Ember.computed('balance', function () {
      var extra = this.get('balance') * -1;

      if (extra > 0) {
        return extra;
      }

      return 0;
    }),
    has_extra_funds: Ember.computed.lt('balance', 0),
    // Used for not showing extra funds
    minimum_balance_total: Ember.computed('balance', function () {
      if (this.get('balance') >= MINIMUM_BALANCE_AMOUNT) {
        return this.get('balance');
      } else {
        return 0;
      }
    })
  });
  var calculated_object = Ember.Object.extend(calculationMixin, {
    all_shipping_costs: (0, _monetize.default)('all_shipping_costs_cents', 'currency'),
    total_tax_costs: (0, _monetize.default)('total_tax_costs_cents', 'currency'),
    balance: (0, _monetize.default)('balance_cents', 'currency'),
    paid: (0, _monetize.default)('paid_cents', 'currency'),
    total: (0, _monetize.default)('total_cents', 'currency')
  });
  _exports.calculated_object = calculated_object;

  var _default = (0, _generatedModel.default)('order').extend(validations, _projectTotal.default, calculationMixin, _prices.default, {
    currency: Ember.computed.readOnly('project.currency'),
    init: function init() {
      this._super.apply(this, arguments); // We have to do this since the service doesn't exist in shared


      var config = Ember.getOwner(this).resolveRegistration('config:environment');
      this.set('config', config);
    },
    locked: (0, _attributes.fragmentArray)('locked'),
    is_locked: Ember.computed.gte('locked.length', 1),
    is_test: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    // Service
    config: null,
    ajax: Ember.inject.service(),
    is_external: Ember.computed.readOnly('source.is_external'),
    is_internal: Ember.computed.readOnly('source.is_internal'),
    // We need to remove this
    upgraded_configuration: Ember.computed('current_configuration.{id,product_reward.price}', 'original_configuration.{id,product_reward.price}', function () {
      if (this.get('current_configuration.product_reward.price') >= this.get('original_configuration.product_reward.price') && this.get('current_configuration.id') !== this.get('original_configuration.id')) {
        return this.get('current_configuration');
      }

      return null;
    }),
    selection_products: Ember.computed.mapBy('selections', 'product'),
    requires_shipping: Ember.computed('selection_products.@each.is_physical', function () {
      return (this.get('selection_products') || Ember.A()).any(function (item) {
        return item.get("is_physical");
      });
    }),
    lines_not_deleted: Ember.computed.filterBy('lines', 'isDeleted', false),
    // Local pickup applies to weight or price based shipping
    lines_for_local_pickup: Ember.computed('local_pickup_address.id', 'lines_not_deleted.@each.{is_bundle,price_data_is_fixed,price_data_is_shipping_fixed,price_data_is_generated,price_data_is_calculation_discount,selection_price_data_is_fixed,selection_price_data_is_shipping_fixed}', function () {
      if (Ember.isBlank(this.get('local_pickup_address.id'))) {
        return Ember.A();
      }

      return this.get('lines_not_deleted').filter(function (order_line) {
        // Order lines are the only generated lines at the moment
        // Only ignore discount lines
        if (order_line.get('price_data_is_generated') && order_line.get('price_data_is_calculation_discount')) {
          return false;
        } // All lines apply


        if (order_line.get('is_bundle')) {
          if (order_line.get('price_data_is_fixed') && order_line.get('price_data_is_shipping_fixed')) {
            // Fixed shipping doesn't count against cap
            return false;
          }

          return true;
        }

        if (order_line.get('selection_price_data_is_fixed') && order_line.get('selection_price_data_is_shipping_fixed')) {
          // Fixed shipping doesn't count against cap
          return false;
        }

        return true;
      });
    }),
    lines_for_weight_based_shipping: Ember.computed('project.shipping_is_weight_based', 'lines_not_deleted.@each.{price_data_is_generated,is_bundle,price_data_is_fixed,price_data_is_shipping_fixed,shipping_is_price_based,selection_price_data_is_fixed,selection_price_data_is_shipping_fixed,selection_shipping_is_price_based}', function () {
      if (this.get('project.shipping_is_weight_based') === false) {
        return Ember.A();
      }

      return this.get('lines_not_deleted').filter(function (order_line) {
        // Order lines are the only generated lines at the moment
        // Ignore any generated line, discount or cost
        if (order_line.get('price_data_is_generated')) {
          return false;
        } // All lines apply


        if (order_line.get('is_bundle')) {
          if (order_line.get('price_data_is_fixed') && order_line.get('price_data_is_shipping_fixed')) {
            // Fixed shipping doesn't count against weight
            return false;
          }

          if (order_line.get('shipping_is_price_based')) {
            // Override shipping price doesn't count against weight
            return false;
          }

          return true;
        }

        if (order_line.get('selection_price_data_is_fixed') && order_line.get('selection_price_data_is_shipping_fixed')) {
          // Fixed shipping doesn't count against weight
          return false;
        }

        if (order_line.get('selection_shipping_is_price_based')) {
          // Override shipping price doesn't count against weight
          return false;
        }

        return true;
      });
    }),
    reward_lines: Ember.computed.filterBy('lines_not_deleted', 'is_reward', true),
    extra_lines: Ember.computed.filterBy('lines_not_deleted', 'is_extra', true),
    extra_lines_to_cap: Ember.computed('local_pickup_address.id', 'project.shipping_is_price_based', 'lines_not_deleted.@each.{is_extra,is_bundle,price_data_is_fixed,price_data_is_shipping_fixed,price_data_is_generated,selection_price_data_is_fixed,selection_price_data_is_shipping_fixed}', function () {
      if (Ember.isPresent(this.get('local_pickup_address.id'))) {
        return Ember.A();
      }

      if (this.get('project.shipping_is_price_based') === false) {
        return Ember.A();
      }

      return this.get('lines_not_deleted').filter(function (order_line) {
        if (!order_line.get('is_extra')) {
          return false;
        } // Order lines are the only generated lines at the moment
        // Ignore any generated line, discount or cost


        if (order_line.get('price_data_is_generated')) {
          return false;
        }

        if (order_line.get('is_bundle')) {
          if (order_line.get('price_data_is_fixed') && order_line.get('price_data_is_shipping_fixed')) {
            // Fixed shipping doesn't count against cap
            return false;
          }

          return true;
        }

        if (order_line.get('selection_price_data_is_fixed') && order_line.get('selection_price_data_is_shipping_fixed')) {
          // Fixed shipping doesn't count against cap
          return false;
        }

        return true;
      });
    }),
    manual_lines: Ember.computed.filterBy('lines_not_deleted', 'is_manual', true),
    external_lines: Ember.computed.filterBy('lines_not_deleted', 'is_external', true),
    shipping_lines: Ember.computed.filterBy('lines_not_deleted', 'is_shipping', true),
    shipping_lines_not_discounts: Ember.computed.filter('lines_not_deleted.@each.{is_shipping,price_data_is_calculation_discount}', function (order_line) {
      if (!order_line.get('is_shipping')) {
        return false;
      }

      return order_line.get('price_data_is_calculation_discount') === false;
    }),
    shipping_lines_discounts: Ember.computed.filter('lines_not_deleted.@each.{is_shipping,price_data_is_calculation_discount}', function (order_line) {
      if (!order_line.get('is_shipping')) {
        return false;
      }

      return order_line.get('price_data_is_calculation_discount') === true;
    }),
    tax_lines: Ember.computed.filterBy('lines_not_deleted', 'is_tax', true),
    transactions_not_deleted: Ember.computed.filterBy('transactions', 'isDeleted', false),
    // START - Customer Steps (Convert to mixin)
    step_to_number: function step_to_number(step) {
      if (step === 'rewards' || Ember.isBlank(step)) {
        return 0;
      }

      if (step === 'extras') {
        return 1;
      }

      if (step === 'shipping') {
        return 2;
      }

      if (step === 'confirm') {
        return 3;
      }

      return -1; // unknown take lowest precedence
    },
    advance_step: function advance_step(step) {
      // If we are completed, only allow confirm to be set
      if (this.get('is_completed') && step !== 'confirm') {
        return true;
      }

      this.set('current_step', step);
      var step_number = this.step_to_number(step);
      var furthest_step_number = this.step_to_number(this.get('furthest_step'));

      if (step_number >= furthest_step_number) {
        return this.set('furthest_step', step);
      }
    },
    can_go_to_step: function can_go_to_step(step) {
      var furthest_step = this.get('furthest_step');
      var step_number = this.step_to_number(step);
      var furthest_step_number = this.step_to_number(furthest_step);

      if (Ember.isBlank(furthest_step) && step_number !== 0) {
        return false;
      }

      return furthest_step_number + 1 >= step_number;
    },
    // END - Customer Steps
    is_completed: Ember.computed.notEmpty('approved_on'),
    is_refused: Ember.computed.notEmpty('refused_on'),
    is_canceled: Ember.computed('canceled_on', 'refused_on', function () {
      return !Ember.isEmpty(this.get('canceled_on')) || !Ember.isEmpty(this.get('refused_on'));
    }),
    getBalance: function getBalance() {
      return this.get('ajax').requestModel(this, 'balance').then(function (result) {
        return calculated_object.create(result);
      });
    },
    // Price calculations
    price: (0, _monetize.default)('price_cents', 'currency'),
    price_data: (0, _attributes.fragment)('price-data', {
      defaultValue: {}
    }),
    // prices: property comes from mixin
    live_product_amount_cents: Ember.computed('lines_not_deleted.@each.prices_product_amount_cents', function () {
      return this.sum_lines('prices_product_amount_cents');
    }),
    live_product_vat_amount_cents: Ember.computed('lines_not_deleted.@each.prices_product_amount_cents', function () {
      return this.sum_lines('prices_product_vat_amount_cents');
    }),
    live_product_vat_category: function live_product_vat_category(category) {
      var result = this.get('lines_not_deleted').reduce(function (value, order_line) {
        return (0, _big.default)(value).plus(order_line.get('live_product_vat_category').call(order_line, category) || 0);
      }, 0);
      return parseFloat((0, _big.default)(result || 0).toFixed(2));
    },
    live_additional_amount_cents: Ember.computed('lines_not_deleted.@each.prices_additional_amount_cents', function () {
      return this.sum_lines('prices_additional_amount_cents');
    }),
    live_shipping_amount_cents: Ember.computed('lines_not_deleted.@each.prices_shipping_amount_cents', function () {
      return this.sum_lines('prices_shipping_amount_cents');
    }),
    live_tax_amount_cents: Ember.computed('lines_not_deleted.@each.prices_tax_amount_cents', function () {
      return this.sum_lines('prices_tax_amount_cents');
    }),
    // Used for tax calculation
    prices_product_amount_cents: Ember.computed.readOnly('prices.product.amount_cents'),
    prices_product_vat_amount_cents: Ember.computed.readOnly('prices.product_vat.amount_cents'),
    prices_additional_amount_cents: Ember.computed.readOnly('prices.additional.amount_cents'),
    sum_lines: function sum_lines(property) {
      var line_type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'lines_not_deleted';
      var result = this.get(line_type).reduce(function (value, order_line) {
        return (0, _big.default)(value).plus(order_line.get(property) || 0);
      }, 0);
      return parseFloat((0, _big.default)(result || 0).toFixed(2));
    },
    renderTemplate: function renderTemplate(template) {
      return this.get('ajax').postModel(this, 'render-template', {
        data: {
          messages: [template]
        }
      }).then(function (xhr) {
        return xhr.messages[0];
      });
    }
  });

  _exports.default = _default;
});