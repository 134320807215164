define("survey/models/product-question", ["exports", "shared/utils/fallback-sort", "survey/utils/generated-model"], function (_exports, _fallbackSort, _generatedModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _generatedModel.default)('product-question').extend({
    // text checks
    is_text: Ember.computed.equal('question_type', 'text'),
    is_text_generic: Ember.computed.equal('sub_type', 'any'),
    is_text_email: Ember.computed.equal('sub_type', 'email'),
    is_text_phone: Ember.computed.equal('sub_type', 'phone'),
    is_text_numeric: Ember.computed.equal('sub_type', 'numeric'),
    is_text_money: Ember.computed.equal('sub_type', 'money'),
    is_multiple: Ember.computed.equal('question_type', 'multiple'),
    is_multiple_single: Ember.computed.equal('sub_type', 'single'),
    is_multiple_quantity: Ember.computed.equal('sub_type', 'quantity'),
    is_multiple_select: Ember.computed.equal('sub_type', 'select'),
    choices_sorted: (0, _fallbackSort.default)('choices', 'text', 'id')
  });

  _exports.default = _default;
});