define("survey/pods/project/setup/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AwrBk2cX",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[\"Setup\"],null],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"ui navigation header\"],[8],[0,\"\\n  Thank you for your interest in \"],[1,[24,[\"project\",\"name\"]],false],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"sub header\"],[8],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"\\n      Checkout isn't quite ready yet. We are busy making the final touches on available selections.\\n      \"],[7,\"br\",true],[8],[9],[0,\"Please try again later.\\n    \"],[9],[0,\"\\n    For more information you can reach out to \"],[7,\"a\",true],[11,\"href\",[29,[\"mailto:\",[24,[\"project\",\"support_email\"]]]]],[8],[1,[24,[\"project\",\"support_name\"]],false],[9],[0,\".\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "survey/pods/project/setup/template.hbs"
    }
  });

  _exports.default = _default;
});