define("survey/templates/components/ss-accordion-tagless", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YIUmRdto",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"title\",\"content\"],[[28,\"component\",[[24,[\"accordionTitleName\"]]],[[\"accordion\"],[[23,0,[]]]]],[28,\"component\",[[24,[\"accordionContentName\"]]],[[\"accordion\"],[[23,0,[]]]]]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "survey/templates/components/ss-accordion-tagless.hbs"
    }
  });

  _exports.default = _default;
});