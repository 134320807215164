define("survey/mixins/analytics", ["exports", "survey/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    pageView: Ember.on('didTransition', function () {
      this.facebookPageView(), this.googlePageView();
    }),
    facebookPageView: function facebookPageView() {
      if (Ember.get(_environment.default, 'facebookAnalytics.pixelId') == null) {
        return;
      } // Don't need to track individual pixels because it fires for both without passing in a pixel id specifically


      window.fbq('track', 'PageView');
    },
    googlePageView: function googlePageView() {
      if (Ember.get(_environment.default, 'googleAnalytics.webPropertyId') == null) {
        return;
      }

      var url_path = this.currentURL;

      if (Ember.isPresent(url_path)) {
        // If it starts with project, we want to parse out the order_id
        if (url_path.startsWith("/project/") === true) {
          url_path = url_path.replace(/\/\d+\//, '/');
        }
      } else {
        url_path = window.location.pathname;
      }

      window.ga('set', 'page', url_path);
      window.ga('send', 'pageview');

      if (this.get('hasTracker')) {
        // This matches analytics.js service tracker name
        window.ga('projectTracker.set', 'page', url_path);
        window.ga('projectTracker.send', 'pageview');
      }
    }
  });

  _exports.default = _default;
});