define("survey/pods/project/questions/controller", ["exports", "survey/mixins/determine-error", "survey/utils/catch-real", "shared/utils/product-mapping-order-selection-filter"], function (_exports, _determineError, _catchReal, _productMappingOrderSelectionFilter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_determineError.default, {
    order: Ember.computed.readOnly('model.order'),
    order_line: Ember.computed.readOnly('model.reward_lines.firstObject'),
    orderTasks: Ember.inject.service(),
    submitted: false,
    is_backend_invalid: false,
    isRunning: Ember.computed.alias('orderTasks.save.isRunning'),
    actions: {
      get_order_selection: function get_order_selection(order_line, product_mapping) {
        var order_selection = order_line.get('selections_not_deleted').find((0, _productMappingOrderSelectionFilter.default)(product_mapping));

        if (Ember.isBlank(order_selection)) {
          throw "Selection for product mapping in product-configuration wasn't found.";
        }

        return order_selection;
      },
      next: function next() {
        var _this = this;

        if (this.get('orderTasks.save.isRunning')) {
          return;
        }

        this.set('submitted', true);
        var order = this.get('order');
        return this.get('orderTasks.save').perform(order, {
          save_shipping_address: false,
          save_non_extras: true,
          save_extras: false
        }).then(function () {
          _this.transitionToRoute('project.review');
        }).catch((0, _catchReal.default)(function (error) {
          return _this.send('determine_error', error);
        }, function (error_type, error) {
          if (error_type === "DS_INVALID") {
            _this.set('is_backend_invalid', true);
          }

          return _this.send('check_error', error);
        }));
      }
    }
  });

  _exports.default = _default;
});