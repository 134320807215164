define("survey/pods/project/errors/over-limit-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LBg6zkPg",
    "block": "{\"symbols\":[\"selection\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n  Over the limit of products\\n  \"],[7,\"div\",false],[12,\"class\",\"ui close button\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"\\n    It looks like one or more of your products is over the limit\\n    allowed.\\n  \"],[9],[0,\"\\n\\n\"],[4,\"each\",[[24,[\"selections\"]]],null,{\"statements\":[[0,\"    \"],[7,\"p\",true],[8],[0,\"You have selected \"],[1,[23,1,[\"selected\"]],false],[0,\" of \"],[7,\"em\",true],[8],[1,[23,1,[\"name\"]],false],[9],[0,\", but there aren't enough remaining in inventory.\"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"has_transaction\"]]],null,{\"statements\":[[0,\"    \"],[7,\"p\",true],[8],[0,\"\\n      The payment has \"],[7,\"b\",true],[8],[0,\"not\"],[9],[0,\" been processed.\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "survey/pods/project/errors/over-limit-modal/template.hbs"
    }
  });

  _exports.default = _default;
});