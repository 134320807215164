define("survey/pods/project/errors/configuration-unavailable-modal/component", ["exports", "survey/mixins/ss-modal"], function (_exports, _ssModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    current_customer: Ember.inject.service('customer'),
    classNames: ['small', 'error'],
    upgraded: false,
    routing: Ember.inject.service('-routing'),
    actions: {
      approve: function approve() {
        window.location = this.get('routing').generateURL('project.rewards');
      }
    }
  });

  _exports.default = _default;
});