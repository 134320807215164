define("survey/components/mobile-detection/component", ["exports", "shared/mixins/mobile-detection"], function (_exports, _mobileDetection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_mobileDetection.default, {
    tagName: ""
  });

  _exports.default = _default;
});