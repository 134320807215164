define("survey/utils/check-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(error, code) {
    if (Ember.isBlank(error) || Ember.isBlank(error.errors)) {
      return false;
    } // otherwise its a validation error from strip


    for (var i = 0; i < error.errors.length; i++) {
      var sub_error = error.errors[i];

      if (Ember.isBlank(sub_error) || Ember.isBlank(sub_error.source) || Ember.isBlank(sub_error.source.pointer)) {
        continue;
      }

      if (Ember.isPresent(sub_error.meta) && Ember.isPresent(sub_error.meta.code) && sub_error.meta.code === code) {
        return true;
      }

      if (sub_error.title === code) {
        return true;
      }
    }

    return false;
  }
});