define("survey/pods/project/selection/controller", ["exports", "survey/mixins/determine-error", "survey/utils/catch-real"], function (_exports, _determineError, _catchReal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_determineError.default, {
    order: Ember.computed.readOnly('model.order'),
    order_selection: Ember.computed.readOnly('model.order_selection'),
    orderTasks: Ember.inject.service(),
    isRunning: Ember.computed.alias('orderTasks.save.isRunning'),
    submitted: false,
    actions: {
      next: function next() {
        var _this = this;

        if (this.get('orderTasks.save.isRunning')) {
          return;
        }

        this.set('submitted', true);
        var options = {
          save_shipping_address: false,
          save_non_extras: true,
          save_extras: true
        };
        var order = this.get('order');
        return this.get('orderTasks.save').perform(order, options).then(function () {
          _this.transitionToRoute('project.review');
        }).catch((0, _catchReal.default)(function (error) {
          return _this.send('determine_error', error);
        }, function (error_type, error) {
          if (error_type === "DS_INVALID") {
            _this.set('is_backend_invalid', true);
          }

          return _this.send('check_error', error);
        }));
      }
    }
  });

  _exports.default = _default;
});