define("survey/components/ss-accordion-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Component.extend({
    classNames: ['ui', 'accordion'],
    accordionComponentName: 'ss-accordion-tagless',
    exclusive: true,
    collapsible: true,
    duration: 500,
    transitionMode: 'fade',
    accordions: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('accordions', Ember.A([]));
    },
    perform: function perform(current) {
      var accordions = this.get('accordions');
      accordions.addObject(current);

      var _iterator = _createForOfIteratorHelper(accordions),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var accordion = _step.value;

          if (current !== accordion) {
            if (this.get('exclusive') === true && accordion.get('isActive') === true) {
              accordion.toggle();
            }
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      if (this.get('collapsible') === false) {
        if (current.get('isActive') === false) {
          current.toggle();
        }
      } else {
        current.toggle();
      }
    }
  });

  _exports.default = _default;
});