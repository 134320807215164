define("survey/pods/project/errors/saving-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0dqRRpWR",
    "block": "{\"symbols\":[\"errors\",\"error\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"bold header\"],[8],[0,\"\\n  Unable to Save Your Information\\n  \"],[7,\"div\",false],[12,\"class\",\"ui close button\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n\"],[4,\"with\",[[28,\"normalize-errors\",[[24,[\"passed_error\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"p\",true],[8],[0,\"\\n      We received the following validation errors while trying to save your information\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"ui bulleted list\"],[8],[0,\"\\n\"],[4,\"each\",[[23,1,[]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"item\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"and\",[[23,2,[\"name\"]],[28,\"not-eq\",[[23,2,[\"name\"]],\"Base\"],null]],null]],null,{\"statements\":[[0,\"            \"],[7,\"b\",true],[8],[1,[23,2,[\"name\"]],false],[0,\":\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n          \"],[1,[23,2,[\"message\"]],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"    \"],[9],[0,\"\\n\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"    Oh no! We were unable to save your changes. Wait a moment, and then try saving again.\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "survey/pods/project/errors/saving-modal/template.hbs"
    }
  });

  _exports.default = _default;
});