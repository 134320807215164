define("survey/initializers/modal", ["exports", "survey/mixins/extend-modal"], function (_exports, _extendModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  // Relative path works since both survey and manage are in lib/...
  function
    /* application */
  initialize() {
    Ember.Component.reopen(_extendModal.default);
    Ember.Controller.reopen(_extendModal.default);
  }

  var _default = {
    name: 'modal',
    initialize: initialize
  };
  _exports.default = _default;
});