define("survey/components/ss-accordion", ["exports", "survey/mixins/ss-accordion-base"], function (_exports, _ssAccordionBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Relative path works since both survey and manage are in lib/...
  var _default = Ember.Component.extend(_ssAccordionBase.default, {
    classNames: ['ui', 'accordion'],
    perform: function perform() {
      this.toggle();
    }
  });

  _exports.default = _default;
});