define("survey/pods/project/address/controller", ["exports", "survey/mixins/determine-error", "shared/helpers/can-access-feature"], function (_exports, _determineError, _canAccessFeature) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_determineError.default, {
    order: null,
    init: function init() {
      this._super.apply(this, arguments); // We need to set this one time here, for the rewards route loading


      if (Ember.isPresent(this.get('order.local_pickup_address.id'))) {
        this.set('order.use_local_pickup', true);
      }
    },
    show_local_pickup: Ember.computed('order.local_pickup_address.id', 'order.project.addresses.length', function () {
      if (Ember.isPresent(this.get('order.local_pickup_address.id'))) {
        return true;
      }

      if (this.get('order.project.addresses.length') > 0) {
        return true;
      }

      return false;
    }),
    can_edit: Ember.computed('order.{is_locked,local_pickup_address.id,project.settings.feature_flags.[]}', function () {
      if (this.get('order.is_locked')) {
        return false;
      }

      if (Ember.isPresent(this.get('order.local_pickup_address.id')) && !(0, _canAccessFeature.canAccessFeature)([this.get('order.project'), 'local-pickup-address-edit'])) {
        return false;
      }

      if ((0, _canAccessFeature.canAccessFeature)([this.get('order.project'), 'disable-survey-address-edit'])) {
        return false;
      }

      return true;
    }),
    actions: {
      next: function next() {
        this.transitionToRoute('project.review');
      },
      toggle_pickup: function toggle_pickup(value) {
        if (value === false) {
          this.set('order.local_pickup_address', null);
        }

        this.set('order.use_local_pickup', value);
      }
    }
  });

  _exports.default = _default;
});