define("survey/pods/project/errors/order-invalid-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kgOSyv4o",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n  \"],[1,[24,[\"project\",\"t\",\"lines\",\"reward\"]],false],[0,\" or \"],[1,[28,\"lowercase\",[[24,[\"project\",\"t\",\"lines\",\"extra\"]]],null],false],[0,\" selection is invalid\\n  \"],[7,\"div\",false],[12,\"class\",\"ui close button\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"\\n    It looks like the \"],[1,[28,\"lowercase\",[[24,[\"project\",\"t\",\"lines\",\"reward\"]]],null],false],[0,\" or \"],[1,[28,\"lowercase\",[[24,[\"project\",\"t\",\"lines\",\"extra\"]]],null],false],[0,\" selection isn't complete.\\n    Please double-check the product selections on the \"],[1,[28,\"lowercase\",[[24,[\"project\",\"t\",\"lines\",\"reward\"]]],null],false],[0,\" and \"],[1,[28,\"lowercase\",[[24,[\"project\",\"t\",\"lines\",\"extra\"]]],null],false],[0,\" page.\\n  \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"has_transaction\"]]],null,{\"statements\":[[0,\"    \"],[7,\"p\",true],[8],[0,\"\\n      Your transaction has \"],[7,\"b\",true],[8],[0,\"not\"],[9],[0,\" been processed.\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "survey/pods/project/errors/order-invalid-modal/template.hbs"
    }
  });

  _exports.default = _default;
});