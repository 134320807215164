define("survey/models/order-transaction", ["exports", "shared/utils/monetize", "ember-cp-validations", "survey/utils/generated-model"], function (_exports, _monetize, _emberCpValidations, _generatedModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var validations = (0, _emberCpValidations.buildValidations)({
    project: {
      description: "Project",
      validators: [(0, _emberCpValidations.validator)('ds-error', true), (0, _emberCpValidations.validator)('presence', true)]
    },
    order: {
      description: "Order",
      validators: [(0, _emberCpValidations.validator)('ds-error', true), (0, _emberCpValidations.validator)('presence', true)]
    },
    provider: {
      description: "Provider",
      validators: [(0, _emberCpValidations.validator)('ds-error', true), (0, _emberCpValidations.validator)('presence', true)]
    },
    amount: {
      description: "Amount",
      validators: [(0, _emberCpValidations.validator)('ds-error', true), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
        gt: 0,
        disabled: Ember.computed('model.is_refund', function () {
          if (this.get('model.is_refund')) {
            return true;
          }

          return false;
        })
      }), (0, _emberCpValidations.validator)('number', {
        lt: 0,
        disabled: Ember.computed('model.is_refund', function () {
          if (!this.get('model.is_refund')) {
            return true;
          }

          return false;
        })
      })]
    },
    shipping_amount: {
      description: "Shipping Amount",
      validators: [(0, _emberCpValidations.validator)('ds-error', true), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
        gte: 0
      })]
    },
    // confirmed: {
    //   description: "Confirmed",
    //   validators: [
    //     validator('ds-error', true),
    //     validator('inclusion', {
    //       in: [true, false]
    //     })
    //   ]
    // },
    is_refund: {
      description: "Is Refund",
      validators: [(0, _emberCpValidations.validator)('ds-error', true), (0, _emberCpValidations.validator)('inclusion', {
        in: [true, false]
      })]
    }
  });

  var _default = (0, _generatedModel.default)('order-transaction').extend(validations, {
    // currency: readOnly('project.currency'),
    ajax: Ember.inject.service(),
    amount: (0, _monetize.default)('amount_cents', 'currency'),
    shipping_amount: (0, _monetize.default)('shipping_amount_cents', 'currency'),
    is_external: Ember.computed('provider', function () {
      return ['Kickstarter', 'Indiegogo'].indexOf(this.get('provider')) >= 0;
    }),
    is_stripe: Ember.computed.equal('provider', 'Stripe'),
    is_paypal: Ember.computed.equal('provider', 'PayPal'),
    is_manual: Ember.computed.equal('provider', 'Manual'),
    actual_created_at: Ember.computed('created_at', 'external_created_at', function () {
      if (Ember.isPresent(this.get('external_created_at'))) {
        return this.get('external_created_at');
      }

      return this.get('created_at');
    })
  });

  _exports.default = _default;
});