define("survey/components/hero-header/component", ["exports", "survey/mixins/mobile-detection"], function (_exports, _mobileDetection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_mobileDetection.default, {
    classNames: 'hero header',
    attributeBindings: 'style',
    style: Ember.computed('project.theme.{hero_image_src,default_hero_background_color,default_hero_background_size,default_hero_background_mobile_offset,default_hero_background_tablet_offset,default_hero_background_offset}', 'is_mobile', 'is_tablet', function () {
      var styles = Ember.A();

      if (Ember.isPresent(this.get('project.theme.hero_image_src'))) {
        styles.push("background-image: url(".concat(this.get('project.theme.hero_image_src'), ")"));
      }

      styles.push("background-color: ".concat(this.get('project.theme.default_hero_background_color')));
      styles.push("background-size: ".concat(this.get('project.theme.default_hero_background_size')));

      if (String(this.get('project.theme.default_hero_background_size')) === 'cover') {
        styles.push("background-position: inherit");
      } else if (this.get('is_mobile')) {
        var background_offset = parseInt(this.get('project.theme.default_hero_background_mobile_offset'), 10);
        styles.push("background-position: calc(50% + ".concat(background_offset, "px) bottom"));
      } else if (this.get('is_tablet')) {
        var _background_offset = parseInt(this.get('project.theme.default_hero_background_tablet_offset'), 10);

        styles.push("background-position: calc(50% + ".concat(_background_offset, "px)"));
      } else {
        var _background_offset2 = parseInt(this.get('project.theme.default_hero_background_offset'), 10);

        styles.push("background-position: calc(50% + ".concat(_background_offset2, "px)"));
      }

      return Ember.String.htmlSafe(styles.join(';'));
    })
  });

  _exports.default = _default;
});