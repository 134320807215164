define("survey/pods/project/rewards/components/configuration-header/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['two', 'column', 'row', 'middle', 'configuration-header', 'component'],
    classNameBindings: ['all_gone:all-gone', 'is_selected:active'],
    is_original_and_external: Ember.computed('is_original', 'order.is_external', function () {
      return this.get('is_original') && this.get('order.is_external');
    })
  });

  _exports.default = _default;
});