define("survey/pods/project/errors/over-max-modal/component", ["exports", "survey/mixins/ss-modal"], function (_exports, _ssModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    current_customer: Ember.inject.service('customer'),
    classNames: ['small', 'error'],
    has_transaction: Ember.computed.notEmpty('transaction'),
    store: Ember.inject.service(),
    selections: Ember.computed('overage', function () {
      var _this = this;

      return this.get('overage').map(function (item) {
        return {
          name: _this.get('store').peekAll('product').findBy('id', item.product_id.toString()).get('name'),
          order_max_quantity: item.order_max_quantity,
          selected: item.selected
        };
      });
    })
  });

  _exports.default = _default;
});