define("survey/mixins/models/order", ["exports", "@ember-data/model", "shared/helpers/can-access-feature", "ember-cp-validations"], function (_exports, _model, _canAccessFeature, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var validations = (0, _emberCpValidations.buildValidations)({
    local_pickup_address: {
      description: "Local Pickup Address",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: Ember.computed('model.use_local_pickup', function () {
          return this.get('model.use_local_pickup') === false;
        })
      })]
    }
  });

  var _default = Ember.Mixin.create(validations, {
    ajax: Ember.inject.service(),
    use_local_pickup: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    product_extra: Ember.computed.readOnly('current_configuration.product_extra'),
    can_reopen: Ember.computed('is_canceled', 'is_locked', 'is_completed', 'is_test', 'project.id', 'project.purpose', 'reward_lines.firstObject.product_bundle.configuration.is_live', function () {
      if (this.get('is_canceled')) {
        return false;
      }

      if (this.get('is_locked')) {
        return false;
      }

      if (this.get('project.purpose') !== 'pledge_management') {
        return false;
      } //############
      // Make sure nobody can make changes right now EXCEPT for the Address
      //############


      if ((0, _canAccessFeature.canAccessFeature)([this.get('project'), 'disable-survey-unlock'])) {
        return false;
      }

      if ((0, _canAccessFeature.canAccessFeature)([this.get('project'), 'disable-survey-reopen'])) {
        return false;
      }

      if (this.get('is_completed') === false) {
        return true;
      }

      if (this.get('is_test')) {
        return true;
      } // if the project is currently live


      if (this.get('reward_lines.firstObject.product_bundle.configuration.is_live')) {
        return true;
      }

      return false;
    }),
    uncomplete: function uncomplete() {
      var _this = this;

      return this.get('ajax').postModel(this, "uncomplete").then(function () {
        _this.set('approved_on', null);
      });
    },
    check_validation: function check_validation() {
      return this.get_validation().then(function (data) {
        if (data.success === true) {
          return true;
        }

        return false;
      });
    },
    get_validation: function get_validation() {
      return this.get('ajax').requestModel(this, 'validate');
    },
    execute_validations: function execute_validations(data) {
      return this.get('ajax').postModel(this, 'approve_automatic_change', {
        data: data
      });
    },
    wave_selection: function wave_selection(selection) {
      var url = 'wave';

      if (Ember.isPresent(selection)) {
        url += "/".concat(selection);
      }

      return this.get('ajax').postModel(this, url);
    }
  });

  _exports.default = _default;
});