define("survey/pods/project/components/address-workflow/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iAgaEmod",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"step\"]],\"update\"],null]],null,{\"statements\":[[0,\"  \"],[14,1,[[28,\"hash\",null,[[\"next\",\"address_compare\",\"address_error\",\"check_error\",\"determine_error\"],[[24,[\"next\"]],[28,\"action\",[[23,0,[]],\"address_compare\"],null],[28,\"action\",[[23,0,[]],\"address_error\"],null],[24,[\"check_error\"]],[24,[\"determine_error\"]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"step\"]],\"compare\"],null]],null,{\"statements\":[[0,\"  \"],[1,[28,\"project/components/address-compare\",null,[[\"order\",\"current_address\",\"updated_address\",\"next\",\"edit\",\"check_error\",\"determine_error\"],[[24,[\"order\"]],[24,[\"current_address\"]],[24,[\"updated_address\"]],[24,[\"next\"]],[28,\"action\",[[23,0,[]],\"edit\"],null],[24,[\"check_error\"]],[24,[\"determine_error\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"step\"]],\"error\"],null]],null,{\"statements\":[[0,\"  \"],[1,[28,\"project/components/address-error\",null,[[\"order\",\"current_address\",\"updated_address\",\"unknown_errors\",\"next\",\"edit\",\"check_error\",\"determine_error\"],[[24,[\"order\"]],[24,[\"current_address\"]],[24,[\"updated_address\"]],[24,[\"unknown_errors\"]],[24,[\"next\"]],[28,\"action\",[[23,0,[]],\"edit\"],null],[24,[\"check_error\"]],[24,[\"determine_error\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "survey/pods/project/components/address-workflow/template.hbs"
    }
  });

  _exports.default = _default;
});