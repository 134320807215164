define("survey/pods/project/refuse/controller", ["exports", "survey/mixins/determine-error", "survey/utils/catch-real"], function (_exports, _determineError, _catchReal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_determineError.default, {
    orderTasks: Ember.inject.service(),
    is_backend_invalid: false,
    reward_line: Ember.computed.readOnly('order.reward_lines.firstObject'),
    actions: {
      cancel: function cancel() {
        var _this = this;

        if (this.get('orderTasks.saving.isRunning')) {
          return;
        }

        this.set('removing', true);
        var order = this.get('order');
        order.set('refused_on', new Date());
        return this.get('orderTasks.save').perform(order, {
          save_non_extras: false,
          save_extras: false,
          save_shipping_address: false
        }).then(function () {
          _this.set('is_backend_invalid', false);

          _this.transitionToRoute('project.review');
        }).catch((0, _catchReal.default)(function (error) {
          return _this.get('determine_error')(error);
        }, function (error_type, error) {
          if (error_type === "DS_INVALID") {
            _this.set('is_backend_invalid', true);
          }

          return _this.send('check_error', error);
        }));
      },
      next: function next(step) {
        if (Ember.isBlank(step)) {
          step = 'project.extras';
        }

        this.transitionToRoute(step);
      }
    }
  });

  _exports.default = _default;
});