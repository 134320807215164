define("survey/helpers/date-now", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dateNow = dateNow;
  _exports.default = void 0;

  function dateNow() {
    return Date.now();
  }

  var _default = Ember.Helper.helper(dateNow);

  _exports.default = _default;
});