define("survey/translations/en-us", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "languages": {
      "de-de": "German",
      "en-us": "English",
      "es-es": "Spanish",
      "fr-fr": "French",
      "ja-jp": "Japanese",
      "nl-nl": "Dutch",
      "sv-se": "Swedish",
      "zh-cn": "Chinese"
    },
    "local_pickup": {
      "description": "You have the <b>option</b> to choose a local pickup address. This allows you to pickup the items yourself at the chosen location and shipping will not be charged.",
      "details": "Selected Pickup Location Details:",
      "header": "Local Pickup Selection",
      "locations": "Pickup Locations",
      "select": "I will pickup my items instead of having them shipped."
    },
    "phrase": {
      "errors": {
        "first": "Go to first error",
        "submit": "Please fix the errors above to continue."
      },
      "inventory_available": "{available, number} of {total, number} Left",
      "processing": "Processing",
      "saving": "Saving",
      "sold_out": "Sold Out"
    },
    "price_banner": "The {product} costs {price, number, USD}",
    "purpose": {
      "ecommerce": {
        "stage": {
          "checkout": {
            "address": {
              "address": "Address",
              "address_line_1": "Line 1",
              "address_line_2": "Line 2",
              "address_missing": "Looks like we are missing some information for your address above. Please double check your address to ensure your shipment isn't delayed.\n",
              "address_override": "My address above is correct",
              "city": "{is_usa, select, true {City} other {Locality or City}}",
              "company_name": "Company Name",
              "country": "Country",
              "country_completed": "Country can't be updated once your selections have been completed.",
              "country_locked": "The country selection has been locked. Please contact the project creator if you need to change it.",
              "first_name": "First Name",
              "full_name": "Full Name",
              "header": "Shipping Address",
              "last_name": "Last Name",
              "middle_name": "Middle {is_split_full, select, true {Name} other {Initial}}",
              "minimum_message": "We don't have enough information to be able to ship your items. {is_usa, select,\n  true {Please fill in all of the required fields.}\n  other {Please fill in at a minimum your Name, Address Line 1, City, and Country information.}\n}\n",
              "navigation": "Shipping",
              "phone_number": "Phone Number",
              "phone_number_missing": "Looks like we are missing your phone number above. A phone number helps to ensure your shipment isn't delayed.",
              "phone_number_override": "I don't have a Phone Number",
              "phone_number_required": "Shipping providers require a phone number{is_international, select, true { for international shipments} other {}}.",
              "postal_code": "{is_usa, select, true {Zip Code} other {Postal Code}}",
              "save_action": "Save Address & Continue to Confirm",
              "state": "{is_usa, select, true {State} other {Province or State}}",
              "sub_header": "Enter the address where you'd like your items shipped."
            },
            "confirm": {
              "agreement_acceptance": "Completion of this page will be taken as acceptance of the <a href=\"https://crowdox.com/legal/pba\" target=\"_blank\">Project Customers Agreement</a> and our <a href=\"https://crowdox.com/legal/terms\" target=\"_blank\">Terms of Use</a>.",
              "credit_card_charged": "Your credit card will be charged when you click submit.",
              "credit_card_label": "Name on card",
              "credit_card_name": "Name",
              "excess_funds": "Extra donated to project",
              "header": "Almost Done, Confirm Your Selections",
              "navigation": "Confirm",
              "newsletter_subscription": "I want to discover more amazing projects and signup for the BackerKit Newsletters.",
              "pay_with_credit_card": "Pay with Credit Card",
              "project_currency_is_different": "(The project currency is different than your selected country. Conversion rates may apply.)",
              "save_action": "Complete & {has_balance, select,\n  true {Pay {amount} Balance}\n  other {Finalize Selections}\n}\n",
              "sub_header": "Please review your items below. {can_reopen, select,\n  true {}\n  other {After confirmation, your selections are final and cannot be updated.}\n}\n"
            },
            "extras": {
              "add_product": "Add {has_reward_selections, select, true {Another} other {To Purchase}}",
              "bundle_products": "Products In Your Bundle",
              "customer_quantity_left": "{count} Left",
              "customer_quantity_max": "{count} per customer",
              "excess_funds": "You have additional funds available that can be used to purchase extras below.",
              "header": "Extras",
              "maximum_allowed": "Maximum Allowed Added",
              "navigation": "Extras",
              "next_action": "Continue to the shipping page",
              "no_extras": "Don't want any extras?",
              "quantity": "Quantity",
              "remove_product": "Remove",
              "reward_selections": "You have {count} in your reward",
              "save_action": "{has_extras, select, true {Save Extras & Continue} other {Continue}}",
              "sold_out": "Sold Out",
              "sub_header": "{extra_mappings, selectordinal,\n  =0 {There are no extras available at the {configuration_name} level.}\n  other {{extra_funds, selectordinal,\n    =0 {Want to purchase extras? Add additional items to your order below.}\n    other {If you pledged for any extras during the campaign, please mark them now.}\n  }}\n}\n",
              "total_quantity_left": "Only {count} left",
              "view_below": "View Below"
            },
            "phrase": {
              "back_to_section": "Back to {name}",
              "bundle": "Bundle",
              "click_thumbnails": "Click thumbnails to zoom",
              "contact": "Contact",
              "country": "Country",
              "country_limited": "Only ships to certain countries",
              "includes": "Includes",
              "powered_by": "<span class=\"powered\">Powered by</span> <span class=\"logo\"><em>Crowd</em> Ox</span>\n",
              "product_questions": "Product Questions?",
              "site_issues": "Site Issues?",
              "we_use_cookies": "We use cookies"
            },
            "review": {
              "header": "Congratulations!",
              "navigation": "Congratulations!",
              "payment_details": "Payment Details",
              "review_experience": "Review Your Experience",
              "sub_header": "Thank you for ordering from {project_name}. Here's a summary of your order information."
            },
            "rewards": {
              "additional_funds": "Any additional funds you pledged for extras can be selected on the next page.",
              "all_configurations_locked": "All of the selections have been marked as locked from the project creator and won't be able to be changed.",
              "any_configuration_locked": "Some of the selections have been marked as locked from the project creator and won't be able to be changed.",
              "choices": "Selections & Choices",
              "choose_action": "{is_external, select, true {Confirm} other {Select}} & Choose Options",
              "estimated_delivery": "Est. delivery: {estimated_delivery}",
              "excess_funds": "You have {excess_funds} in excess funds available. You can apply the excess to extras on the next page{can_upgrade, select, true { or to upgrade your selection below} other {}}.",
              "header": "Confirm Your Selection",
              "manual_choices": "Manually Added Selections & Choices",
              "manual_selection_includes": "Manually Added Selections Include",
              "manual_selection_includes_description": "These are selections that were added manually by the project creator",
              "navigation": "Bundle",
              "original_configuration": "Pledged on {site}",
              "other_choices": "Other Selections & Choices",
              "other_selection_includes": "Other Selections Include",
              "other_selection_includes_description": "These are selections previously purchased outside of Crowd Ox",
              "save_action": "Save & Continue",
              "select_configuration": "Select this Bundle",
              "selection_includes": "Selection Includes",
              "selection_none": "No Items Included in Selection",
              "sub_header": "Below you'll find what you {is_external, select, true {backed} other {selected}} {site}. Please review and choose your selections to proceed.",
              "title": "Bundle Selection",
              "upgrade_configuration": "Or Choose a Different Selection Below",
              "upgraded_configuration": "You have chosen a different selection below.",
              "your_info": "Your Info"
            },
            "summary": {
              "balance": "Balance",
              "bundle": "{name} Total",
              "customs_duty": "Customs Duty",
              "external": "Other Purchase Total",
              "extras": "Extras Total",
              "manual": "Manual Added Total",
              "sales_tax": "Sales Tax",
              "value_added_tax": "Value Added Tax (VAT)"
            }
          }
        }
      },
      "pledge_management": {
        "stage": {
          "checkout": {
            "address": {
              "address": "Address",
              "address_line_1": "Line 1",
              "address_line_2": "Line 2",
              "address_missing": "Looks like we are missing some information for your address above. Please double check your address to ensure your shipment isn't delayed.\n",
              "address_override": "My address above is correct",
              "city": "{is_usa, select, true {City} other {Locality or City}}",
              "company_name": "Company Name",
              "country": "Country",
              "country_completed": "Country can't be updated once your selections have been completed.",
              "country_locked": "The country selection has been locked. Please contact the project creator if you need to change it.",
              "first_name": "First Name",
              "full_name": "Full Name",
              "header": "Shipping Address",
              "last_name": "Last Name",
              "middle_name": "Middle {is_split_full, select, true {Name} other {Initial}}",
              "minimum_message": "We don't have enough information to be able to ship your items. {is_usa, select,\n  true {Please fill in all of the required fields.}\n  other {Please fill in at a minimum your Name, Address Line 1, City, and Country information.}\n}\n",
              "navigation": "Shipping",
              "phone_number": "Phone Number",
              "phone_number_missing": "Looks like we are missing your phone number above. A phone number helps to ensure your shipment isn't delayed.",
              "phone_number_override": "I don't have a Phone Number",
              "phone_number_required": "Shipping providers require a phone number{is_international, select, true { for international shipments} other {}}.",
              "postal_code": "{is_usa, select, true {Zip Code} other {Postal Code}}",
              "save_action": "Save Address & Continue to Confirm",
              "state": "{is_usa, select, true {State} other {Province or State}}",
              "sub_header": "Enter the address where you'd like your items shipped."
            },
            "confirm": {
              "agreement_acceptance": "Completion of this page will be taken as acceptance of the <a href=\"https://crowdox.com/legal/pba\" target=\"_blank\">Project Customers Agreement</a> and our <a href=\"https://crowdox.com/legal/terms\" target=\"_blank\">Terms of Use</a>.",
              "credit_card_charged": "Your credit card will be charged when you click submit.",
              "credit_card_label": "Name on card",
              "credit_card_name": "Name",
              "excess_funds": "Extra donated to project",
              "header": "Almost Done, Confirm Your Selections",
              "navigation": "Confirm",
              "newsletter_subscription": "I want to discover more amazing projects and signup for the BackerKit Newsletters.",
              "pay_with_credit_card": "Pay with Credit Card",
              "project_currency_is_different": "(The project currency is different than your selected country. Conversion rates may apply.)",
              "save_action": "Complete & {has_balance, select,\n  true {Pay {amount} Balance}\n  other {Finalize Selections}\n}\n",
              "sub_header": "Please review your items below. {can_reopen, select,\n  true {}\n  other {After confirmation, your selections are final and cannot be updated.}\n}\n"
            },
            "extras": {
              "add_product": "Add {has_reward_selections, select, true {Another} other {To Purchase}}",
              "bundle_products": "Products In Your Bundle",
              "customer_quantity_left": "{count} Left",
              "customer_quantity_max": "{count} per customer",
              "excess_funds": "You have additional funds available that can be used to purchase extras below.",
              "header": "Extras",
              "maximum_allowed": "Maximum Allowed Added",
              "navigation": "Extras",
              "next_action": "Continue to the shipping page",
              "no_extras": "Don't want any extras?",
              "quantity": "Quantity",
              "remove_product": "Remove",
              "reward_selections": "You have {count} in your reward",
              "save_action": "{has_extras, select, true {Save Extras & Continue} other {Continue}}",
              "sold_out": "Sold Out",
              "sub_header": "{extra_mappings, selectordinal,\n  =0 {There are no extras available at the {configuration_name} level.}\n  other {{extra_funds, selectordinal,\n    =0 {Want to purchase extras? Add additional items to your order below.}\n    other {If you pledged for any extras during the campaign, please mark them now.}\n  }}\n}\n",
              "total_quantity_left": "Only {count} left",
              "view_below": "View Below"
            },
            "phrase": {
              "back_to_section": "Back to {name}",
              "bundle": "Bundle",
              "click_thumbnails": "Click thumbnails to zoom",
              "contact": "Contact",
              "country": "Country",
              "country_limited": "Only ships to certain countries",
              "includes": "Includes",
              "powered_by": "<span class=\"powered\">Powered by</span> <span class=\"logo\"><em>Crowd</em> Ox</span>\n",
              "product_questions": "Product Questions?",
              "site_issues": "Site Issues?",
              "we_use_cookies": "We use cookies"
            },
            "review": {
              "header": "Congratulations!",
              "navigation": "Congratulations!",
              "payment_details": "Payment Details",
              "review_experience": "Review Your Experience",
              "sub_header": "Thank you for supporting {project_name}. Here's a review of your finalized pledge information."
            },
            "rewards": {
              "additional_funds": "Any additional funds you pledged for extras can be selected on the next page.",
              "all_configurations_locked": "All of the selections have been marked as locked from the project creator and won't be able to be changed.",
              "any_configuration_locked": "Some of the selections have been marked as locked from the project creator and won't be able to be changed.",
              "choices": "Selections & Choices",
              "choose_action": "{is_external, select, true {Confirm} other {Select}} & Choose Options",
              "estimated_delivery": "Est. delivery: {estimated_delivery}",
              "excess_funds": "You have {excess_funds} in excess funds available. You can apply the excess to extras on the next page{can_upgrade, select, true { or to upgrade your selection below} other {}}.",
              "header": "Confirm Your Selection",
              "manual_choices": "Manually Added Selections & Choices",
              "manual_selection_includes": "Manually Added Selections Include",
              "manual_selection_includes_description": "These are selections that were added manually by the project creator",
              "navigation": "Rewards",
              "original_configuration": "Pledged on {site}",
              "other_choices": "Other Selections & Choices",
              "other_selection_includes": "Other Selections Include",
              "other_selection_includes_description": "These are selections previously purchased outside of Crowd Ox",
              "save_action": "Save & Continue",
              "select_configuration": "Select this Reward",
              "selection_includes": "Selection Includes",
              "selection_none": "No Items Included in Selection",
              "sub_header": "Below you'll find what you {is_external, select, true {backed} other {selected}} {site}. Please review and choose your selections to proceed.",
              "title": "Reward Selection",
              "upgrade_configuration": "Or Choose a Different Selection Below",
              "upgraded_configuration": "You have chosen a different selection below.",
              "your_info": "Your Info"
            },
            "summary": {
              "balance": "Balance",
              "bundle": "{name} Total",
              "customs_duty": "Customs Duty",
              "external": "Other Purchase Total",
              "extras": "Extras Total",
              "manual": "Manual Added Total",
              "sales_tax": "Sales Tax",
              "value_added_tax": "Value Added Tax (VAT)"
            }
          }
        }
      },
      "pre_order": {
        "stage": {
          "checkout": {
            "address": {
              "address": "Address",
              "address_line_1": "Line 1",
              "address_line_2": "Line 2",
              "address_missing": "Looks like we are missing some information for your address above. Please double check your address to ensure your shipment isn't delayed.\n",
              "address_override": "My address above is correct",
              "city": "{is_usa, select, true {City} other {Locality or City}}",
              "company_name": "Company Name",
              "country": "Country",
              "country_completed": "Country can't be updated once your selections have been completed.",
              "country_locked": "The country selection has been locked. Please contact the project creator if you need to change it.",
              "first_name": "First Name",
              "full_name": "Full Name",
              "header": "Shipping Address",
              "last_name": "Last Name",
              "middle_name": "Middle {is_split_full, select, true {Name} other {Initial}}",
              "minimum_message": "We don't have enough information to be able to ship your items. {is_usa, select,\n  true {Please fill in all of the required fields.}\n  other {Please fill in at a minimum your Name, Address Line 1, City, and Country information.}\n}\n",
              "navigation": "Shipping",
              "phone_number": "Phone Number",
              "phone_number_missing": "Looks like we are missing your phone number above. A phone number helps to ensure your shipment isn't delayed.",
              "phone_number_override": "I don't have a Phone Number",
              "phone_number_required": "Shipping providers require a phone number{is_international, select, true { for international shipments} other {}}.",
              "postal_code": "{is_usa, select, true {Zip Code} other {Postal Code}}",
              "save_action": "Save Address & Continue to Confirm",
              "state": "{is_usa, select, true {State} other {Province or State}}",
              "sub_header": "Enter the address where you'd like your items shipped."
            },
            "confirm": {
              "agreement_acceptance": "Completion of this page will be taken as acceptance of the <a href=\"https://crowdox.com/legal/pba\" target=\"_blank\">Project Customers Agreement</a> and our <a href=\"https://crowdox.com/legal/terms\" target=\"_blank\">Terms of Use</a>.",
              "credit_card_charged": "Your credit card will be charged when you click submit.",
              "credit_card_label": "Name on card",
              "credit_card_name": "Name",
              "excess_funds": "Extra donated to project",
              "header": "Almost Done, Confirm Your Selections",
              "navigation": "Confirm",
              "newsletter_subscription": "I want to discover more amazing projects and signup for the BackerKit Newsletters.",
              "pay_with_credit_card": "Pay with Credit Card",
              "project_currency_is_different": "(The project currency is different than your selected country. Conversion rates may apply.)",
              "save_action": "Complete & {has_balance, select,\n  true {Pay {amount} Balance}\n  other {Finalize Selections}\n}\n",
              "sub_header": "Please review your items below. {can_reopen, select,\n  true {}\n  other {After confirmation, your selections are final and cannot be updated.}\n}\n"
            },
            "extras": {
              "add_product": "Add {has_reward_selections, select, true {Another} other {To Purchase}}",
              "bundle_products": "Products In Your Bundle",
              "customer_quantity_left": "{count} Left",
              "customer_quantity_max": "{count} per customer",
              "excess_funds": "You have additional funds available that can be used to purchase extras below.",
              "header": "Extras",
              "maximum_allowed": "Maximum Allowed Added",
              "navigation": "Extras",
              "next_action": "Continue to the shipping page",
              "no_extras": "Don't want any extras?",
              "quantity": "Quantity",
              "remove_product": "Remove",
              "reward_selections": "You have {count} in your reward",
              "save_action": "{has_extras, select, true {Save Extras & Continue} other {Continue}}",
              "sold_out": "Sold Out",
              "sub_header": "{extra_mappings, selectordinal,\n  =0 {There are no extras available at the {configuration_name} level.}\n  other {{extra_funds, selectordinal,\n    =0 {Want to purchase extras? Add additional items to your order below.}\n    other {If you pledged for any extras during the campaign, please mark them now.}\n  }}\n}\n",
              "total_quantity_left": "Only {count} left",
              "view_below": "View Below"
            },
            "phrase": {
              "back_to_section": "Back to {name}",
              "bundle": "Bundle",
              "click_thumbnails": "Click thumbnails to zoom",
              "contact": "Contact",
              "country": "Country",
              "country_limited": "Only ships to certain countries",
              "includes": "Includes",
              "powered_by": "<span class=\"powered\">Powered by</span> <span class=\"logo\"><em>Crowd</em> Ox</span>\n",
              "product_questions": "Product Questions?",
              "site_issues": "Site Issues?",
              "we_use_cookies": "We use cookies"
            },
            "review": {
              "header": "Congratulations!",
              "navigation": "Congratulations!",
              "payment_details": "Payment Details",
              "review_experience": "Review Your Experience",
              "sub_header": "Thank you for ordering from {project_name}. Here's a summary of your order information."
            },
            "rewards": {
              "additional_funds": "Any additional funds you pledged for extras can be selected on the next page.",
              "all_configurations_locked": "All of the selections have been marked as locked from the project creator and won't be able to be changed.",
              "any_configuration_locked": "Some of the selections have been marked as locked from the project creator and won't be able to be changed.",
              "choices": "Selections & Choices",
              "choose_action": "{is_external, select, true {Confirm} other {Select}} & Choose Options",
              "estimated_delivery": "Est. delivery: {estimated_delivery}",
              "excess_funds": "You have {excess_funds} in excess funds available. You can apply the excess to extras on the next page{can_upgrade, select, true { or to upgrade your selection below} other {}}.",
              "header": "Confirm Your Selection",
              "manual_choices": "Manually Added Selections & Choices",
              "manual_selection_includes": "Manually Added Selections Include",
              "manual_selection_includes_description": "These are selections that were added manually by the project creator",
              "navigation": "Bundle",
              "original_configuration": "Pledged on {site}",
              "other_choices": "Other Selections & Choices",
              "other_selection_includes": "Other Selections Include",
              "other_selection_includes_description": "These are selections previously purchased outside of Crowd Ox",
              "save_action": "Save & Continue",
              "select_configuration": "Select this Bundle",
              "selection_includes": "Selection Includes",
              "selection_none": "No Items Included in Selection",
              "sub_header": "Below you'll find what you {is_external, select, true {backed} other {selected}} {site}. Please review and choose your selections to proceed.",
              "title": "Bundle Selection",
              "upgrade_configuration": "Or Choose a Different Selection Below",
              "upgraded_configuration": "You have chosen a different selection below.",
              "your_info": "Your Info"
            },
            "summary": {
              "balance": "Balance",
              "bundle": "{name} Total",
              "customs_duty": "Customs Duty",
              "external": "Other Purchase Total",
              "extras": "Extras Total",
              "manual": "Manual Added Total",
              "sales_tax": "Sales Tax",
              "value_added_tax": "Value Added Tax (VAT)"
            }
          }
        }
      }
    },
    "survey": {
      "rewards": {
        "upgrading_from_limited_configuration": {
          "acknowledge": "I understand",
          "description": "Your previously chosen configuration <b>{previous_configuration_name}</b> has a limited quantity. After upgrading, you may not be able to change back to the previous configuration if others claim the remaining quantity."
        }
      }
    }
  };
  _exports.default = _default;
});