define("survey/mirage/config", ["exports", "survey/config/environment", "ember-inflector", "shared/mirage/helpers/crud"], function (_exports, _environment, _emberInflector, _crud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _default() {
    this.urlPrefix = "".concat(_environment.default.urls.api); // make this `http://localhost:8080`, for example, if your API is on a different server

    /*
      Shorthand cheatsheet:
       this.get('/posts');
      this.post('/posts');
      this.get('/posts/:id');
      this.put('/posts/:id'); // or this.patch
      this.del('/posts/:id');
       http://www.ember-cli-mirage.com/docs/v0.4.x/shorthands/
    */
    // testing only configuration

    this.passthrough("/write-coverage");
    this.passthrough('https://syndication.twitter.com/settings');
    this.get("/orders/session/me", function () {// do nothing
    });
    this.namespace = "/api/v2";
    this.post('/order-addresses/:id/validate', function (schema, request) {
      var id = request.params.id || request.queryParams.id;
      var address = schema.orderAddresses.find(id);

      if (address.address_1 === "invalid") {
        return {
          current_address: this.serialize(address).data,
          status: 'none'
        };
      }

      if (address.address_1 === "compare") {
        var updated_address = this.serialize(address).data;
        updated_address.id = (parseInt(updated_address.id, 10) + 1).toString();
        return {
          current_address: this.serialize(address).data,
          updated_address: updated_address,
          status: 'partial'
        };
      }

      return {
        current_address: this.serialize(address).data,
        status: 'validated'
      };
    });
    this.get('/orders/:id/balance', function () {
      return {
        currency: 'USD',
        all_shipping_costs_cents: 0,
        total_tax_costs_cents: 0,
        balance_cents: 0,
        paid_cents: 0,
        total_cents: 0
      };
    });
    this.get('/orders/:id/validate', function () {
      return {
        success: true
      };
    });
    this.post('/orders/:id/render-template', function (_schema, request) {
      return {
        messages: JSON.parse(request.requestBody).messages
      };
    });

    var _iterator = _createForOfIteratorHelper(_crud.COMMON_ENDPOINTS),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var type = _step.value;
        var plural_type = (0, _emberInflector.pluralize)(type);
        this.get("/".concat(plural_type));
        this.post("/".concat(plural_type));
        this.get("/".concat(plural_type, "/:id"));
        this.put("/".concat(plural_type, "/:id"));
        this.patch("/".concat(plural_type, "/:id"));
        this.del("/".concat(plural_type, "/:id"));
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
  }
});