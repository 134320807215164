define("survey/pods/application/route", ["exports", "authentication/mixins/application-route", "survey/mixins/modal-actions", "shared/utils/load-geography"], function (_exports, _applicationRoute, _modalActions, _loadGeography) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRoute.default, _modalActions.default, {
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    beforeModel: function beforeModel() {
      var _this = this;

      return Ember.RSVP.resolve(this._super.apply(this, arguments)).then(function () {
        return _this.get('intl').setLocale('en-us');
      });
    },
    model: function model() {
      // router Service currentURL is returning null
      if (this.get('_router.url').indexOf('/confirm') !== 0) {
        return new Ember.RSVP.hash({
          geography: (0, _loadGeography.default)(this.get('store'))
        });
      }
    }
  });

  _exports.default = _default;
});