define("survey/components/ui-error-messages/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Em/5N4If",
    "block": "{\"symbols\":[\"error\"],\"statements\":[[4,\"if\",[[24,[\"errors\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"ui error message ui-error-messages component\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"ui bulleted list\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"errors\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"item\"],[8],[0,\"\\n\"],[4,\"if\",[[23,1,[\"attribute\"]]],null,{\"statements\":[[0,\"            \"],[7,\"b\",true],[8],[1,[28,\"compute\",[[28,\"action\",[[23,0,[]],\"friendly\"],null],[23,1,[\"attribute\"]]],null],false],[0,\":\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n          \"],[1,[23,1,[\"message\"]],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "survey/components/ui-error-messages/template.hbs"
    }
  });

  _exports.default = _default;
});