define("survey/pods/project/closed/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "89ipNDBV",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[\"Closed\"],null],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"ui navigation header\"],[8],[0,\"\\n  Thank you for your interest in \"],[1,[24,[\"project\",\"name\"]],false],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"sub header\"],[8],[0,\"\\n    We no longer have an active checkout. For more information you can reach out to \"],[7,\"a\",true],[11,\"href\",[29,[\"mailto:\",[24,[\"project\",\"support_email\"]]]]],[8],[1,[24,[\"project\",\"support_name\"]],false],[9],[0,\".\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "survey/pods/project/closed/template.hbs"
    }
  });

  _exports.default = _default;
});