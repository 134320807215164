define("survey/models/vat-category", ["exports", "survey/utils/generated-model"], function (_exports, _generatedModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import Ember from 'ember';
  var _default = (0, _generatedModel.default)('vat-category').reopen({});

  _exports.default = _default;
});