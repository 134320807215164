define("survey/mixins/restrict-by-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(status) {
    return Ember.Mixin.create({
      beforeModel: function beforeModel(transition) {
        var _this = this;

        return Ember.RSVP.resolve(this._super.apply(this, arguments)).then(function () {
          // If we are already transitioning to something else, return
          if (transition != null && transition.isAborted) {
            return;
          }

          var order = _this.controllerFor('project').get('order');

          if (order == null) {
            return;
          }

          var approved = order.get('is_completed');
          var canceled = order.get('is_canceled');
          var next_step = 'rewards';

          if (Ember.isPresent(order.get('furthest_step'))) {
            next_step = order.get('furthest_step');
          }

          next_step = 'project.' + next_step;

          if (status.includes('incomplete') && !approved && !canceled) {
            return;
          }

          if (status.includes('completed') && approved) {
            return;
          }

          if (status.includes('canceled') && canceled) {
            return;
          }

          if (approved || canceled) {
            _this.replaceWith('project.review');

            return;
          }

          _this.replaceWith(next_step);
        });
      }
    });
  }
});