define("survey/pods/project/extras/components/mapping-section/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['item', 'mapping-section', 'component']
  });

  _exports.default = _default;
});