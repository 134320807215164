define("survey/utils/client-pages", ["exports", "shared/helpers/can-access-feature"], function (_exports, _canAccessFeature) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.get_next_page = get_next_page;
  _exports.has_client_page = has_client_page;
  _exports.has_feature_flag_page = has_feature_flag_page;

  // Determine if we have a custom client page
  function has_client_page(project) {
    if (Ember.isBlank(project)) {
      return false;
    } //############
    // Make sure nobody can make changes right now EXCEPT for the Address
    //############


    if ((0, _canAccessFeature.canAccessFeature)([project, 'wave-shipping-7th'])) {
      return true;
    }

    return false;
  }

  function has_feature_flag_page(project) {
    if (Ember.isBlank(project)) {
      return false;
    }

    if ((0, _canAccessFeature.canAccessFeature)([project, 'wave-shipping'])) {
      return true;
    }

    return false;
  }

  function get_next_page(project) {
    if (has_client_page(project)) {
      var client_page = project.get('settings.survey.wave-shipping');

      if (Ember.isPresent(client_page)) {
        return client_page;
      }
    }

    if (has_feature_flag_page(project)) {
      // Just assuming feature flag goes to wave shipping, we can look at differences in future
      return 'project.wave';
    }

    return 'project.confirm';
  }
});