define("survey/models/project-zone", ["exports", "ember-data-model-fragments/attributes", "ember-cp-validations", "survey/utils/generated-model", "survey/utils/monetize", "survey/mixins/zone"], function (_exports, _attributes, _emberCpValidations, _generatedModel, _monetize, _zone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    vat: {
      description: "Vat",
      validators: [(0, _emberCpValidations.validator)('ds-error')]
    }
  });

  var _default = (0, _generatedModel.default)('project-zone').extend(Validations, _zone.default, {
    shipping: (0, _attributes.fragment)('zone-shipping'),
    vat: (0, _attributes.fragment)('zone-vat'),
    percent_decimal: (0, _monetize.default)('percent', null, 100),
    prices_sorted: Ember.computed('shipping.prices.@each.amount_cents', function () {
      return this.get('shipping.prices').sortBy('amount_cents');
    }),
    first_amount: Ember.computed.readOnly('prices_sorted.firstObject.amount'),
    last_amount: Ember.computed.readOnly('prices_sorted.lastObject.amount'),
    shipping_cents: Ember.computed.readOnly('prices_sorted.firstObject.amount_cents'),
    is_shipping_weight_based: Ember.computed.equal('cost_type', 'shipping_weight_based'),
    is_sales_tax: Ember.computed.equal('cost_type', 'sales_tax'),
    is_vat: Ember.computed.equal('cost_type', 'vat'),
    is_customs: Ember.computed.equal('cost_type', 'customs')
  });

  _exports.default = _default;
});