define("survey/initializers/ember-inflector", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize() {
    _emberInflector.default.inflector.uncountable('dice');
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});