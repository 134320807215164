define("survey/mixins/models/project", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    twitter_url: Ember.computed('twitter_handle', function () {
      var handle = this.get('twitter_handle');
      return handle ? "https://twitter.com/".concat(handle) : null;
    }),
    twitter_handle: Ember.computed('urls.twitter', function () {
      if (Ember.isBlank(this.get('urls.twitter'))) {
        return null;
      } // First, strip out any @ characters


      var url = this.get('urls.twitter').replace('@', ''); // Case 1 - url is just a twitter handle like crowdoxapp

      if (url.indexOf('twitter.com/') === -1) {
        return url;
      } // Case 2 - url is a full, valid url like https://twitter.com/crowdoxapp


      return url.slice(url.lastIndexOf('/') + 1, url.length);
    })
  });

  _exports.default = _default;
});