define("survey/pods/project/shipping/controller", ["exports", "survey/mixins/determine-error", "survey/utils/client-pages"], function (_exports, _determineError, _clientPages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_determineError.default, {
    analytics: Ember.inject.service(),
    orderTasks: Ember.inject.service(),
    project: Ember.computed.readOnly('order.project'),
    project_source: Ember.computed.readOnly('order.source'),
    actions: {
      next_with_step: function next_with_step() {
        var _this = this;

        return this.get('orderTasks.save').perform(this.get('order'), {
          step: 'shipping',
          save_non_extras: false,
          save_extras: false,
          shipping_address_country_only: true
        }).then(function () {
          return _this.send('next');
        });
      },
      next: function next() {
        this.get('analytics').pageEvent(this.get('order'), 'shipping');
        this.transitionToRoute((0, _clientPages.get_next_page)(this.get('project')));
      }
    }
  });

  _exports.default = _default;
});