define("survey/models/product-variation", ["exports", "shared/utils/fallback-sort", "survey/utils/generated-model", "ember-data-model-fragments/attributes", "shared/utils/image"], function (_exports, _fallbackSort, _generatedModel, _attributes, _image) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _generatedModel.default)('product-variation').extend({
    details_data: (0, _attributes.fragment)('product-details', {
      defaultValue: {}
    }),
    // Aliases to support existing functionality
    details: Ember.computed.readOnly('details_data.details'),
    details_sorted: (0, _fallbackSort.default)('details_data.details', 'variant_sort_order', 'variant_id'),
    image_src: (0, _image.default)('image_path')
  });

  _exports.default = _default;
});