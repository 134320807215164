define("survey/pods/project/shipping/route", ["exports", "survey/mixins/restrict-by-condition", "survey/mixins/restrict-by-step", "survey/mixins/restrict-by-status", "survey/mixins/scroll-route"], function (_exports, _restrictByCondition, _restrictByStep, _restrictByStatus, _scrollRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Status is first priority then step
  var _default = Ember.Route.extend(_restrictByCondition.default, (0, _restrictByStatus.default)(['incomplete']), (0, _restrictByStep.default)('shipping'), _scrollRoute.default, {
    model: function model() {
      return null;
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var order = this.controllerFor('project').get('order');
      controller.set('order', order);
    }
  });

  _exports.default = _default;
});