define("survey/pods/confirm/index/route", ["exports", "shared/utils/load-geography", "@sentry/browser"], function (_exports, _loadGeography, Sentry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    beforeModel: function beforeModel() {
      var _this = this;

      var parentParams = this.paramsFor('confirm');
      var routeParams = this.paramsFor(this.routeName);
      var params = Ember.assign({}, parentParams, routeParams);
      var creator_url_name = encodeURIComponent(params.creator_url_name);
      var project_url_name = encodeURIComponent(params.project_url_name);
      var token = encodeURIComponent(params.token || params['amp;token']);
      var order_id = params.order_id;

      if (Ember.isBlank(order_id)) {
        this.transitionTo('confirm.not-found');
        return;
      }

      return this.get('session').authenticate('authenticator:custom', order_id, token).catch(function (error) {
        // Catching and logging the error to sentry so we can try to find
        // some sort of reason why they are happening
        try {
          Sentry.setUser({
            order_id: order_id
          });
          return Sentry.captureException(error, {
            tags: {
              slug: "".concat(creator_url_name, "/").concat(project_url_name, "/").concat(order_id)
            },
            extra: {
              order_id: order_id,
              route: 'confirm.index'
            }
          });
        } finally {
          _this.transitionTo('confirm.not-found');
        }
      });
    },
    afterModel: function afterModel() {
      if (this.get('session.isAuthenticated')) {
        return new Ember.RSVP.hash({
          geography: (0, _loadGeography.default)(this.get('store'))
        });
      }
    },
    redirect: function redirect() {
      if (Ember.isBlank(this.get('session.data.authenticated.order_id'))) {
        this.transitionTo('confirm.not-found');
        return;
      }

      var applicationParams = this.paramsFor('application');
      var parentParams = this.paramsFor('confirm');
      var routeParams = this.paramsFor(this.routeName);
      var params = Ember.assign({}, applicationParams, parentParams, routeParams);
      var creator_url_name = encodeURIComponent(params.creator_url_name);
      var project_url_name = encodeURIComponent(params.project_url_name); // 2020-05-26 we renamed their misspelled identifier. I kept an alias in Mailgun for the email
      // this treats it the same for Survey.
      //
      // TODO: Around October 2020 we should be able to remove this

      if (project_url_name === 'swtych---eu') {
        project_url_name = 'swytch---eu';
      }

      var order_id = params.order_id,
          step = params.step,
          auto = params.auto; // Application controller

      var _ga = params._ga,
          utm_source = params.utm_source,
          utm_medium = params.utm_medium,
          utm_campaign = params.utm_campaign,
          utm_term = params.utm_term,
          utm_content = params.utm_content;

      if (Ember.isPresent(this.get('session.data.authenticated.email'))) {
        Sentry.setUser({
          email: this.get('session.data.authenticated.email'),
          order_id: order_id,
          project_id: this.get('session.data.authenticated.project_id')
        });
      } // Pass additional params through


      var additional_params = [];
      var keyValues = {
        auto: auto,
        _ga: _ga,
        utm_source: utm_source,
        utm_medium: utm_medium,
        utm_campaign: utm_campaign,
        utm_term: utm_term,
        utm_content: utm_content
      };

      for (var _i = 0, _Object$keys = Object.keys(keyValues); _i < _Object$keys.length; _i++) {
        var key = _Object$keys[_i];
        var value = keyValues[key];

        if (Ember.isPresent(value)) {
          additional_params.push("".concat(key, "=").concat(encodeURIComponent(value)));
        }
      }

      var query_params = additional_params.join('&');

      if (Ember.isPresent(query_params)) {
        query_params = '?' + query_params;
      } // Verify steps


      if (step === 'cancel') {
        step = 'refuse';
      }

      if ((step || '').indexOf('selection') === 0) {
        this.replaceWith("/project/".concat(creator_url_name, "/").concat(project_url_name, "/").concat(order_id, "/").concat(step).concat(query_params));
        return;
      }

      if (!(step === 'rewards' || step === 'extras' || step === 'shipping' || step === 'confirm' || step === 'address' || step === 'questions' || step === 'refuse' || step === 'reopen')) {
        step = 'rewards';
      }

      this.replaceWith("/project/".concat(creator_url_name, "/").concat(project_url_name, "/").concat(order_id, "/").concat(step).concat(query_params));
    }
  });

  _exports.default = _default;
});