define("survey/pods/project/errors/unable-to-update-order-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Uj65E3Tn",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n  You've Already Confirmed\\n  \"],[7,\"div\",false],[12,\"class\",\"ui close button\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n  You've gone through the process and previously confirmed your reward on another device or in another window.\\n  Please take a moment to \"],[7,\"a\",false],[12,\"href\",\"javascript:\"],[3,\"action\",[[23,0,[]],\"approve\"]],[8],[0,\"review your selections.\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "survey/pods/project/errors/unable-to-update-order-modal/template.hbs"
    }
  });

  _exports.default = _default;
});