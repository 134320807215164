define("survey/models/order-selection", ["exports", "shared/utils/computed-default-properties", "shared/utils/monetize", "ember-cp-validations", "survey/utils/generated-model", "ember-data-model-fragments/attributes", "shared/mixins/prices", "shared/mixins/project-total", "big.js", "@ember-data/model"], function (_exports, _computedDefaultProperties, _monetize, _emberCpValidations, _generatedModel, _attributes, _prices, _projectTotal, _big, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var validations = (0, _emberCpValidations.buildValidations)({
    project: {
      description: "Project",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    order: {
      description: "Order",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    line: {
      description: "Order Line",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    product: {
      description: "Product",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    product_variation: {
      description: "Product Variation",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', {
        presence: Ember.computed('model.product.variations.[]', function () {
          // Don't use has_variations as that is only for variations to configure
          return this.get('model.product.variations.length') > 0;
        })
      })]
    },
    product_option_mapping: {
      description: "Product Option Mapping",
      validators: [(0, _emberCpValidations.validator)('ds-error')]
    },
    product_option: {
      description: "Product Option",
      validators: [(0, _emberCpValidations.validator)('ds-error')]
    },
    quantity: {
      description: "Quantity",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
        integer: true,
        gte: 1
      })]
    },
    questions: {
      description: "Selection Questions",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('has-many'), (0, _emberCpValidations.validator)('presence', {
        presence: Ember.computed('model.product.has_questions', function () {
          return this.get('model.product.has_questions') || false;
        })
      })]
    },
    is_abstract: {
      description: "Unconfigured Products",
      validators: [(0, _emberCpValidations.validator)('inclusion', {
        in: [false],
        description: Ember.computed('model.quantity', function () {
          if (this.get('model.quantity') === 1) {
            return "There is 1 remaining product that needs to be configured";
          }

          return "There are ".concat(this.get('model.quantity'), " remaining products that need to be configured");
        })
      })]
    }
  });

  var _default = (0, _generatedModel.default)('order-selection').extend(validations, _prices.default, {
    currency: Ember.computed.readOnly('project.currency'),
    // Relationships
    locked: (0, _attributes.fragmentArray)('locked'),
    // Attributes
    // This is used as a flag so we know which selection isn't really a selection
    is_abstract: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    // Properties
    is_locked: Ember.computed.gte('locked.length', 1),
    // Line properties aliases
    is_reward: Ember.computed.readOnly('line.is_reward'),
    is_extra: Ember.computed.readOnly('line.is_extra'),
    is_manual: Ember.computed.readOnly('line.is_manual'),
    is_external: Ember.computed.readOnly('line.is_external'),
    in_bundle: Ember.computed.readOnly('line.is_bundle'),
    image_path: (0, _computedDefaultProperties.default)('product_variation.image_path', 'product.image_path'),
    shipping_is_price_based: Ember.computed.or('product.shipping_is_price_based', 'project.shipping_is_price_based'),
    has_product_option: Ember.computed.readOnly('product_option.is_option'),
    has_choices: Ember.computed.or('product.has_choices', 'product_option.is_option'),
    // Helpers to avoid deprecation warnings on @each nested properties
    is_question: Ember.computed('product.is_question', function () {
      if (this.get('product.is_question') === true) {
        return true;
      } // Something change between ember 3.8 and 3.12 where the previous filters on is_question wasn't filtering on false.


      return false;
    }),
    // Price calculations
    price: (0, _monetize.default)('price_cents', 'currency'),
    price_data: (0, _attributes.fragment)('price-data', {
      defaultValue: {}
    }),
    // prices: property comes from mixin
    // Live calculations for prices
    live_product_amount_cents: Ember.computed('live_product_amount_per_quantity_cents', 'quantity', function () {
      return (this.get('live_product_amount_per_quantity_cents') || 0) * (this.get('quantity') || 0);
    }),
    live_product_amount_per_quantity_cents: Ember.computed('in_bundle', 'product.{is_question,price_cents}', function () {
      // If we have a bundle, its always 0
      if (this.get('in_bundle')) {
        return 0;
      } // Questions never have prices


      if (this.get('product.is_question')) {
        return 0;
      }

      return this.get('product.price_cents') || 0;
    }),
    live_product_vat_amount_cents: Ember.computed('live_product_amount_per_quantity_cents', 'quantity', function () {
      return (this.get('live_product_vat_amount_per_quantity_cents') || 0) * (this.get('quantity') || 0);
    }),
    live_product_vat_amount_per_quantity_cents: Ember.computed('in_bundle', 'product.{is_question,price_cents}', 'project.vat_categories.[]', function () {
      // If we have a bundle, its always 0
      if (this.get('in_bundle')) {
        return 0;
      } // Questions never have prices


      if (this.get('product.is_question')) {
        return 0;
      }

      var that = this;
      var categories = this.get('project.vat_categories').toArray();
      var result = categories.reduce(function (value, category) {
        return (0, _big.default)(value).plus(that.get("product.vat_price_cents.".concat(category.get('public_id'))) || 0);
      }, 0);
      return parseFloat((0, _big.default)(result || 0).toFixed(2));
    }),
    live_product_vat_category: function live_product_vat_category(category) {
      return this.live_product_vat_category_amount_per_quantity_cents(category) * (this.get('quantity') || 0);
    },
    live_product_vat_category_amount_per_quantity_cents: function live_product_vat_category_amount_per_quantity_cents(category) {
      // If we have a bundle, its always 0
      if (this.get('in_bundle')) {
        return 0;
      } // Questions never have prices


      if (this.get('product.is_question')) {
        return 0;
      }

      return this.get("product.vat_price_cents.".concat(category)) || 0;
    },
    live_additional_amount_cents: Ember.computed('live_additional_amount_per_quantity_cents', 'quantity', function () {
      return (this.get('live_additional_amount_per_quantity_cents') || 0) * (this.get('quantity') || 0);
    }),
    live_additional_amount_per_quantity_cents: Ember.computed('in_bundle', 'line.price_data.{is_fixed,settings.additional}', 'product_option_mapping.{id,additional_price_cents}', function () {
      // If we have a bundle, use the additional price check
      if (this.get('in_bundle')) {
        if (Ember.isBlank(this.get('product_option_mapping.id'))) {
          return 0;
        } // If the order line is fixed and the settings for additional is included, its 0
        // This is a hack right now. It should return the actual cost, and then the price should be 0
        // To fix this, we would need to update prices mixin to look at the line to see if its fixed also.


        if (this.get('line.price_data.is_fixed') && this.get('line.price_data.settings.additional') === 'included') {
          return 0;
        }

        return this.get('product_option_mapping.additional_price_cents') || 0;
      }

      return 0;
    }),
    live_shipping_amount_cents: Ember.computed('live_shipping_amount_per_quantity_cents', 'quantity', function () {
      return (this.get('live_shipping_amount_per_quantity_cents') || 0) * (this.get('quantity') || 0);
    }),
    live_shipping_amount_per_quantity_cents: Ember.computed('in_bundle', 'shipping_is_price_based', 'product.is_physical', 'product.shipping_product_based_zone_locations.@each.{country,state,shipping_cents}', 'order.shipping_address.{state,country}', function () {
      // If we have a bundle, its always 0
      if (this.get('in_bundle')) {
        return 0;
      }

      if (this.get('shipping_is_price_based') === false) {
        return 0;
      } // Only physical products have shipping


      if (!this.get('product.is_physical')) {
        return 0;
      } // We've eliminated all options, now calculate based on product


      var country = this.get('order.shipping_address.country');
      var state = this.get('order.shipping_address.state');
      var zone_location = (0, _projectTotal.find_zone_location)(this.get('product'), 'shipping_product_based_zone_locations', country, state);

      if (Ember.isPresent(zone_location)) {
        return zone_location.get('shipping_cents') || 0;
      }

      return 0;
    }),
    live_tax_amount_cents: Ember.computed(function () {
      return 0;
    }),
    live_tax_amount_per_quantity_cents: Ember.computed(function () {
      return 0;
    }),
    product_total_weight: Ember.computed('product_per_quantity_weight', 'quantity', function () {
      var product_per_quantity_weight = this.get('product_per_quantity_weight') || 0;
      var product_weight_big = (0, _big.default)(product_per_quantity_weight).times(this.get('quantity') || 0);
      return parseFloat(product_weight_big.toFixed(2));
    }),
    product_per_quantity_weight: Ember.computed('product.{is_physical,weight}', function () {
      if (this.get('product.is_physical') === false) {
        return 0;
      }

      if (this.get('price_data.is_fixed') && this.get('price_data.settings.shipping') === 'fixed') {
        return this.get('price_data.settings.shipping_weight_per_quantity') || 0;
      }

      var weight = this.get('product.weight');

      if (Ember.isPresent(weight)) {
        return weight;
      }

      return 0;
    }),
    // Price aliases to help with order line reference
    prices_product_amount_cents: Ember.computed.readOnly('prices.product.amount_cents'),
    prices_product_vat_amount_cents: Ember.computed.readOnly('prices.product_vat.amount_cents'),
    prices_additional_amount_cents: Ember.computed.readOnly('prices.additional.amount_cents'),
    prices_shipping_amount_cents: Ember.computed.readOnly('prices.shipping.amount_cents'),
    prices_tax_amount_cents: Ember.computed.readOnly('prices.tax.amount_cents'),
    // Display helpers for generated lines
    display_name: Ember.computed('product.{id,name}', 'product_option.{id,name}', function () {
      if (Ember.isPresent(this.get('product.id'))) {
        return this.get('product.name');
      }

      if (Ember.isPresent(this.get('product_option.id'))) {
        return this.get('product_option.name');
      }

      return null;
    }),
    display_description: Ember.computed('product.{id,description}', 'product_option.{id,description}', function () {
      if (Ember.isPresent(this.get('product.id'))) {
        return this.get('product.description');
      }

      if (Ember.isPresent(this.get('product_option.id'))) {
        return this.get('product_option.description');
      }

      return null;
    })
  });

  _exports.default = _default;
});