define("survey/components/share-tweet/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6r12zRn7",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\",true],[10,\"href\",\"https://twitter.com/share\"],[10,\"class\",\"twitter-share-button\"],[11,\"data-url\",[29,[[22,\"url\"]]]],[11,\"data-text\",[29,[\"I just helped make \",[24,[\"project\",\"name\"]],\" a reality. \",[22,\"twitter_handles\"]]]],[10,\"data-size\",\"large\"],[10,\"data-related\",\"crowdoxapp\"],[10,\"data-count\",\"none\"],[10,\"data-hashtags\",\"funded\"],[8],[0,\"Tweet\"],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "survey/components/share-tweet/template.hbs"
    }
  });

  _exports.default = _default;
});