define("survey/pods/project/errors/transaction-invalid-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UDw7ht74",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n  Something didn't match\\n  \"],[7,\"div\",false],[12,\"class\",\"ui close button\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"\\n    We didn't continue due to a mismatch between\\n    the browser and our backend servers.\\n    Your transaction has \"],[7,\"b\",true],[8],[0,\"not\"],[9],[0,\" been processed.\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"p\",true],[8],[0,\"\\n    Please reload your browser and try again.\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "survey/pods/project/errors/transaction-invalid-modal/template.hbs"
    }
  });

  _exports.default = _default;
});