define("survey/pods/project/address/route", ["exports", "survey/mixins/restrict-by-status", "survey/mixins/scroll-route"], function (_exports, _restrictByStatus, _scrollRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend((0, _restrictByStatus.default)(['completed']), _scrollRoute.default, {
    model: function model() {
      return null;
    },
    redirect: function redirect() {
      var order = this.controllerFor('project').get('order');

      if (!order.get('requires_shipping')) {
        this.replaceWith('project.review');
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var order = this.controllerFor('project').get('order');
      controller.set('order', order);
    }
  });

  _exports.default = _default;
});