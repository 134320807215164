define("survey/components/error-modal/component", ["exports", "survey/mixins/ss-modal", "@sentry/browser"], function (_exports, _ssModal, Sentry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    current_customer: Ember.inject.service('customer'),
    classNames: ['small', 'error'],
    init: function init() {
      this._super.apply(this, arguments);

      if (Ember.isPresent(this.get('error'))) {
        Sentry.captureException(this.get('error'));
      }
    }
  });

  _exports.default = _default;
});