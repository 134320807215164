define("survey/pods/project/components/order-selection-extension/component", ["exports", "shared/helpers/can-access-feature"], function (_exports, _canAccessFeature) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['floated', 'right', 'order-selection-extension', 'component'],
    project: Ember.computed.readOnly('order_selection.project'),
    order_selection: null,
    can_edit: Ember.computed('order_selection.{is_locked,order.is_locked,has_choices,product_option.is_option}', 'project.id', function () {
      if ( // This disables editing after a completed survey, globally.
      (0, _canAccessFeature.canAccessFeature)([this.get('project'), 'disable-survey-unlock']) || // Hate to do this, but if today's release goes well we can
      // take these 3 lines off and release for everyone.
      this.get('project.id') !== '100739' || // Cannot edit a locked selections
      this.get('order_selection.is_locked') || this.get('order_selection.order.is_locked') || // Currently not allowing edits on product options
      this.get('order_selection.product_option.is_option')) {
        return false;
      } // Whether it's in a bundle or if it's not we only want to show the edit if
      // there is some sort of question, variation or option.


      return this.get('order_selection.has_choices');
    })
  });

  _exports.default = _default;
});