define("survey/pods/project/errors/configuration-unavailable-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Qj+rfw4+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"bold header\"],[8],[0,\"\\n  Reward Sold Out\\n  \"],[7,\"div\",false],[12,\"class\",\"ui close button\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n  Sorry, but the reward you selected is no longer available to purchase.\\n  There was a limited quantity remaining for \"],[7,\"b\",true],[8],[1,[24,[\"order\",\"reward_lines\",\"firstObject\",\"display_name\"]],false],[9],[0,\", and they are now gone.\\n  Please return to the \"],[7,\"a\",false],[12,\"href\",\"javascript:\"],[3,\"action\",[[23,0,[]],\"approve\"]],[8],[0,\"reward selection step\"],[9],[0,\" and select a different reward.\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "survey/pods/project/errors/configuration-unavailable-modal/template.hbs"
    }
  });

  _exports.default = _default;
});