define("survey/pods/project/rewards/components/configuration-information/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['one', 'column', 'row', 'configuration-information', 'component'],
    intl: Ember.inject.service(),
    order: Ember.computed.readOnly('order_line.order'),
    project: Ember.computed.readOnly('order.project'),
    total_external_selections_count: Ember.computed('order.external_lines.@each.total_selections_count', function () {
      return this.get('order.external_lines').reduce(function (value, order_line) {
        return value + (order_line.get('total_selections_count') || 0);
      }, 0);
    }),
    total_manual_selections_count: Ember.computed('order.manual_lines.@each.total_selections_count', function () {
      return this.get('order.manual_lines').reduce(function (value, order_line) {
        return value + (order_line.get('total_selections_count') || 0);
      }, 0);
    }),
    total_selections: Ember.computed('order_line.available_mappings_sorted.length', 'total_external_selections_count', 'total_manual_selections_count', function () {
      return this.get('order_line.available_mappings_sorted.length') + this.get('total_external_selections_count') + this.get('total_manual_selections_count');
    }),
    has_selections: Ember.computed.gt('total_selections', 0),
    estimated_delivery: Ember.computed('order_line.product_bundle.configuration.estimated_delivery', function () {
      var estimated_delivery = this.get('order_line.product_bundle.configuration.estimated_delivery');

      if (Ember.isPresent(estimated_delivery)) {
        if (/^\d{1,4}[/-]\d{1,2}(?:[/-]\d{1,4})?$/.test(estimated_delivery)) {
          var date = Date.parse(estimated_delivery);

          if (!isNaN(date)) {
            return this.get('intl').formatDate(date, {
              timeZone: 'UTC',
              month: 'long',
              year: 'numeric'
            });
          }
        }

        return estimated_delivery;
      }

      return null;
    })
  });

  _exports.default = _default;
});