define("survey/models/locked", ["exports", "ember-data-model-fragments/fragment", "@ember-data/model"], function (_exports, _fragment, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fragment.default.extend({
    process: (0, _model.attr)('string'),
    at: (0, _model.attr)('date'),
    tracking_number: (0, _model.attr)('string'),
    shipping_method: (0, _model.attr)('string'),
    friendly_process_name: Ember.computed('process', function () {
      switch (this.get('process')) {
        case 'export':
          return 'Export';

        case 'ship':
          return 'Shipment Tracking';

        case 'digital':
          return 'Digital Fulfillment';

        default:
          return Ember.String.capitalize(this.get('process') || '');
      }
    })
  });

  _exports.default = _default;
});