define("survey/components/ss-accordion-tagless", ["exports", "survey/mixins/ss-accordion-base"], function (_exports, _ssAccordionBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Relative path works since both survey and manage are in lib/...
  var _default = Ember.Component.extend(_ssAccordionBase.default, {
    tagName: '',
    group: null,
    // accordion group
    init: function init() {
      this._super.apply(this, arguments);

      if (Ember.isBlank(this.get('name'))) {
        this.set('name', "accordion-".concat(Ember.guidFor(this)));
      }
    },
    perform: function perform() {
      this.get('group').perform(this);
    }
  });

  _exports.default = _default;
});