define("survey/pods/project/reopen/route", ["exports", "survey/mixins/restrict-by-condition", "survey/mixins/restrict-by-status", "survey/mixins/scroll-route"], function (_exports, _restrictByCondition, _restrictByStatus, _scrollRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_restrictByCondition.default, (0, _restrictByStatus.default)(['completed']), _scrollRoute.default, {
    model: function model() {
      return this.controllerFor('project').get('order');
    },
    redirect: function redirect(order) {
      // The review page can only be viewed by someone who is
      // - Approved
      if (Ember.isBlank(order.get('approved_on'))) {
        this.replaceWith('project.rewards');
      }
    }
  });

  _exports.default = _default;
});