define("survey/utils/test-customer", ["exports", "survey/models/order", "shared/tests/helpers/model-helpers"], function (_exports, _order, _modelHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createOrder = createOrder;
  _exports.getBalance = getBalance;
  _exports.signin = signin;
  _exports.unlockOrder = unlockOrder;

  function signin(session, configuration_ids, token) {
    var configuration_id;

    if (Ember.isArray(configuration_ids)) {
      configuration_id = configuration_ids[0];
    } else {
      configuration_id = configuration_ids;
    }

    session.get('session')._setup('authenticator:custom', {
      token: token,
      order_id: "test-".concat(configuration_id),
      customer_id: 0,
      project_id: 0,
      email: "test@crowdox.com",
      type: "authentication_token",
      is_test: true
    }, true);
  }

  function createOrder(store, project, orderTasks, configuration_ids, country_iso3, transaction_amount) {
    return new Ember.RSVP.Promise(function (resolve, reject) {
      var customer = (0, _modelHelpers.create_customer)(store, project, {
        email: 'test@crowdox.com',
        full_name: 'Test Tester'
      });
      var configuration_id;

      if (Ember.isArray(configuration_ids)) {
        configuration_id = configuration_ids[0];
      } else {
        configuration_id = configuration_ids;
      }

      var configuration = project.get('configurations').findBy('id', configuration_id.toString());

      if (Ember.isBlank(configuration)) {
        return reject();
      }

      var order = (0, _modelHelpers.create_order)(store, project, customer, configuration.get('source'), {
        original_configuration: configuration,
        current_configuration: configuration,
        is_test: true
      });

      if (Ember.isPresent(transaction_amount)) {
        transaction_amount = parseFloat(transaction_amount);

        if (!isNaN(transaction_amount)) {
          (0, _modelHelpers.create_order_transaction)(store, order, {
            provider: configuration.get('source.site').capitalize(),
            amount_cents: transaction_amount * project.get('currency_base'),
            confirmed: true
          });
        }
      }

      if (Ember.isPresent(country_iso3)) {
        var country = store.peekAll('country').findBy('iso3', country_iso3);

        if (Ember.isPresent(country)) {
          (0, _modelHelpers.create_order_address)(store, order, true, {
            country: country
          });
        }
      }

      return resolve([order]);
    });
  }

  function getBalance(order) {
    return new Ember.RSVP.Promise(function (resolve) {
      var result = {
        // We don't use any of these commented out properties on calculated
        currency: order.get('project.currency'),
        all_shipping_costs_cents: order.get('all_shipping_costs_cents'),
        total_tax_costs_cents: order.get('total_tax_costs_cents'),
        balance_cents: order.get('balance_cents'),
        paid_cents: order.get('paid_cents'),
        total_cents: order.get('total_cents') // rewards: {
        //   cost_cents: order.get('reward_costs_cents'),
        //   reward_cents: order.get('reward_costs_cents'),
        //   shipping_cents: order.get('reward_shipping_costs_cents'),
        //   total_cents: order.get('total_reward_costs_cents')
        // },
        // extras: {
        //   cost_cents: order.get('extra_costs_cents'),
        //   shipping_cents: order.get('extra_shipping_costs_cents'),
        //   total_cents: order.get('total_extra_costs_cents')
        // },
        // payments: {
        //   direct_cents: order.get('direct_payments_cents'),
        //   external_cents: order.get('external_payments_cents'),
        //   manual_cents: order.get('manual_payments_cents'),
        //   total_cents: order.get('total_payments_cents')
        // }

      };
      return resolve(_order.calculated_object.create(result));
    });
  }

  function unlockOrder(order) {
    return new Ember.RSVP.Promise(function (resolve) {
      order.set('approved_on', null);
      return resolve();
    });
  }
});