define("survey/adapters/application", ["exports", "ember-inflector", "survey/config/environment", "ember-simple-auth/mixins/data-adapter-mixin", "ember-data/adapters/json-api"], function (_exports, _emberInflector, _environment, _dataAdapterMixin, _jsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend(_dataAdapterMixin.default, {
    host: _environment.default.urls.api,
    namespace: 'api/v2',
    session: Ember.inject.service(),
    pathForType: function pathForType(modelName) {
      var decamelized = Ember.String.decamelize(modelName);
      var pluralized = (0, _emberInflector.pluralize)(decamelized);
      var slashed = pluralized.replace(/\//g, '-').replace(/_/g, '-');
      return slashed;
    },
    headers: Ember.computed('session.data.authenticated.{type,token,order_id}', function () {
      var headers = {};

      var _ref = this.get('session.data.authenticated') || {},
          token = _ref.token,
          type = _ref.type,
          order_id = _ref.order_id;

      if (Ember.isPresent(token) && type === 'authentication_token') {
        headers['Authorization'] = "Token token=".concat(token, ", order_id=").concat(order_id);
      }

      return headers;
    })
  });

  _exports.default = _default;
});