define("survey/initializers/environment", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize() {
    _jquery.default.support.cors = true;

    _jquery.default.ajaxPrefilter(function (options, originalOptions, jqXHR) {
      // options.crossDomain =
      //   crossDomain: true
      options.xhrFields = {
        withCredentials: true
      }; // Forcing this header so we can do request.xhr? in rails

      return jqXHR.setRequestHeader("X-Requested-With", "XMLHttpRequest");
    }); // Only attribute bind to specific components
    // Bindings to all, causes tagless components to throw an error

  }

  var _default = {
    name: 'environment',
    initialize: initialize
  };
  _exports.default = _default;
});