define("survey/components/ss-accordion-content", ["exports", "survey/mixins/ss-transition", "jquery"], function (_exports, _ssTransition, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Relative path works since both survey and manage are in lib/...
  var _default = Ember.Component.extend(_ssTransition.default, {
    classNames: ['content'],
    _isActive: Ember.computed.readOnly('accordion.isActive'),
    isOpening: false,
    isClosing: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.get('accordion').registerContent(this);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      Ember.run.scheduleOnce('afterRender', this, function () {
        if (this.get('_isActive')) {
          (0, _jquery.default)(this.element).addClass('active');
        }
      });
    },
    toggle: function toggle() {
      if (this.get('isDestroyed') || this.get('isDestroying')) {
        return;
      }

      if (this.get('_isActive')) {
        if (this.isOpened() || this.get('isOpening')) {
          return;
        }

        this.open();
      } else {
        if (this.isClosed() || this.get('isClosing')) {
          return;
        }

        this.close();
      }
    },
    // Transition Defaults
    transitionScope: '> *:not(.ui.dimmer)',
    transitionMode: Ember.computed.readOnly('accordion.transitionMode'),
    transitionDuration: Ember.computed.readOnly('accordion.duration'),
    isOpened: function isOpened() {
      var scope = (0, _jquery.default)(this.element);
      return scope.hasClass('active') && !scope.hasClass('animating');
    },
    isClosed: function isClosed() {
      var scope = (0, _jquery.default)(this.element);
      return !scope.hasClass('active');
    },
    open: function open() {
      this.transitionIn();
      var scope = (0, _jquery.default)(this.element);
      this.setProperties({
        isOpening: true,
        isClosing: false
      });
      scope.slideDown(this.get('transitionDuration'), 'easeOutQuad', Ember.run.bind(this, this._opened));
      scope.addClass('active animating');
    },
    _opened: function _opened() {
      if (this.get('isDestroyed') || this.get('isDestroying')) {
        return;
      }

      if (this.isClosed() || this.get('isClosing') || this.isOpened()) {
        return;
      }

      var scope = (0, _jquery.default)(this.element);
      scope.addClass('active');
      scope.removeClass('animating');
      this.set('isOpening', false);
    },
    close: function close() {
      this.transitionOut();
      var scope = (0, _jquery.default)(this.element);
      this.setProperties({
        isOpening: false,
        isClosing: true
      });
      scope.addClass('active animating');
      scope.slideUp(this.get('transitionDuration'), 'easeOutQuad', Ember.run.bind(this, this._closed));
    },
    _closed: function _closed() {
      if (this.get('isDestroyed') || this.get('isDestroying')) {
        return;
      }

      if (this.isOpened() || this.get('isOpening') || this.isClosed()) {
        return;
      }

      (0, _jquery.default)(this.element).removeClass('active animating');
      this.set('isClosing', false);
    }
  });

  _exports.default = _default;
});