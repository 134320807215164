define("survey/models/price-data-settings", ["exports", "ember-data-model-fragments/fragment", "@ember-data/model"], function (_exports, _fragment, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fragment.default.extend({
    // Generated settings
    category: (0, _model.attr)('string'),
    symbol: (0, _model.attr)('string'),
    custom_name: (0, _model.attr)('string'),
    calculation_type: (0, _model.attr)('string'),
    // Fixed Settings
    product: (0, _model.attr)('string'),
    shipping: (0, _model.attr)('string'),
    additional: (0, _model.attr)('string') // Only used for bundle

  });

  _exports.default = _default;
});