define("survey/models/project-translation", ["exports", "survey/utils/generated-model"], function (_exports, _generatedModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _generatedModel.default)('project-translation').extend({
    initializeEmptyProperties: function initializeEmptyProperties() {
      var _this = this;

      ["rewards", "extras", "address", "confirm", "review", "refuse", "phrase", "summary"].forEach(function (key) {
        if (_this.get("checkout.".concat(key)) == null) {
          _this.set("checkout.".concat(key), {});
        }
      });
    }
  });

  _exports.default = _default;
});