define("survey/serializers/application", ["exports", "ember-data/serializers/json-api"], function (_exports, _jsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend({
    modelNameFromPayloadKey: function modelNameFromPayloadKey(key) {
      key = this._super(key);
      var product_iterable = ['product/reward', 'product/extra'];

      for (var i = 0, item; i < product_iterable.length; i++) {
        item = product_iterable[i];

        if (key === item) {
          key = "product";
        }
      }

      return key;
    },
    // JSON API on the backend, only passes through a { data: } key if we included the relationship.
    // If we didn't include the relationship, the api returns an empty hash {}
    // What we are looking for here is, if the property on the relationship is an empty hash, remove it from loading data
    // Otherwise, ember clears all associations with that, even though we didn't side laod it
    extractRelationships: function extractRelationships() {
      var relationships = this._super.apply(this, arguments); // Now only pass through relationships that have actual data
      // Any data, just not a total blank {}


      var keys = Object.keys(relationships);

      for (var _i = 0, _keys = keys; _i < _keys.length; _i++) {
        var key = _keys[_i];
        var prop = relationships[key];

        if (relationships.hasOwnProperty(key)) {
          if (Object.getOwnPropertyNames(prop).length === 0) {
            delete relationships[key];
          }
        }
      }

      return relationships;
    },
    extractErrors: function extractErrors() {
      var error_hash = this._super.apply(this, arguments);

      for (var _i2 = 0, _Object$keys = Object.keys(error_hash); _i2 < _Object$keys.length; _i2++) {
        var key = _Object$keys[_i2];
        var errors = error_hash[key];
        var dashed_name = Ember.String.dasherize(key);
        var format = "".concat(dashed_name, " - ");

        for (var i = 0; i < errors.length; i++) {
          var error = errors[i];

          if (error.startsWith(format)) {
            errors.splice(i, 1, error.substring(format.length));
          }
        }
      }

      return error_hash;
    }
  });

  _exports.default = _default;
});