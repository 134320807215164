define("survey/components/address-dropdown/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    loading: false,
    actions: {
      changed: function changed(event) {
        var iso3 = event.target.value;
        var country = this.get('countries').findBy('iso3', iso3);
        this.onChange(country);
      }
    }
  });

  _exports.default = _default;
});