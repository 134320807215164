define("survey/pods/project/inspection/route", ["exports", "survey/mixins/restrict-by-status", "survey/mixins/scroll-route"], function (_exports, _restrictByStatus, _scrollRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend((0, _restrictByStatus.default)(['incomplete']), _scrollRoute.default, {
    ajax: Ember.inject.service(),
    model: function model() {
      var order = this.controllerFor('project').get('order');
      return order.get_validation();
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('order', this.controllerFor('project').get('order'));
    },
    redirect: function redirect(model) {
      // For some reason if we get to this page and there isn't an error, then redirect back to confirm
      if (model == null || model.success === true || model.errors == null || Object.keys(model.errors).length === 0) {
        this.replaceWith('project.confirm');
      }
    }
  });

  _exports.default = _default;
});