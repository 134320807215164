define("survey/pods/confirm/error/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    renderTemplate: function renderTemplate() {
      this.render('shared/not-found', {
        into: 'application'
      });
      this.render('confirm/error', {
        into: 'shared/not-found'
      });
    }
  });

  _exports.default = _default;
});