define("survey/pods/project/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    application: Ember.inject.controller(),
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    currentPath: Ember.computed.alias('router.currentRouteName'),
    project: null,
    order: null,
    queryParams: ['token', 'configurations', 'country', 'transaction'],
    token: null,
    configurations: null,
    country: null,
    transaction: null,
    // never disable step one
    step_one: Ember.computed('currentPath', function () {
      return false;
    }),
    // only disable step two if we aren't on or passed it
    step_two: Ember.computed('currentPath', function () {
      if (this.get('currentPath') === 'project.extras' || this.get('currentPath') === 'project.shipping' || this.get('currentPath') === 'project.wave' || this.get('currentPath') === 'project.inspection' || this.get('currentPath') === 'project.confirm') {
        return false; // don't disable, we are on extras
      }

      return true;
    }),
    // only disable step three if we aren't on or passed it
    step_three: Ember.computed('currentPath', function () {
      if (this.get('currentPath') === 'project.shipping' || this.get('currentPath') === 'project.wave' || this.get('currentPath') === 'project.inspection' || this.get('currentPath') === 'project.confirm') {
        return false; // don't disable, we are on shipping
      }

      return true;
    }),
    // only disable step four if we aren't on or passed it
    step_four: Ember.computed('currentPath', function () {
      if (this.get('currentPath') === 'project.inspection' || this.get('currentPath') === 'project.confirm') {
        return false; // don't disable, we are one payment or have passed it
      }

      return true;
    }),
    hide_menu: Ember.computed('currentPath', function () {
      return this.get('currentPath') !== 'project.rewards' && this.get('currentPath') !== 'project.extras' && this.get('currentPath') !== 'project.shipping' && this.get('currentPath') !== 'project.wave' && this.get('currentPath') !== 'project.inspection' && this.get('currentPath') !== 'project.confirm';
    }),
    activeLocale: Ember.computed.readOnly('intl.locale.firstObject'),
    locales: Ember.computed.readOnly('model.locales'),
    actions: {
      changeLocale: function changeLocale(event) {
        this.get('intl').set('locale', [event.target.value]);
      }
    }
  });

  _exports.default = _default;
});