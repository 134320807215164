define("survey/mixins/ss-transition", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    transitionMode: null,
    transitionDuration: null,
    transitionScope: null,
    init: function init() {
      this._super.apply(this, arguments);

      var transitionMode = this.get('transitionMode');

      if (Ember.isBlank(transitionMode)) {
        if (window.console != null && window.console.warn != null) {
          window.console.warn("transitionMode isn't specified. It should be a string (i.e. fade). Using default");
        }
      }

      var transitionDuration = this.get('transitionDuration');

      if (Ember.isBlank(transitionDuration)) {
        if (window.console != null && window.console.warn != null) {
          window.console.warn("transitionDuration isn't specified. It should be an integer for milliseconds (i.e. 500).Using default");
        }
      }
    },
    transitionIn: function transitionIn() {
      if (this.isElementVisible() && !this.isAnimatingOut()) {
        return;
      }

      if (this.isAnimatingIn()) {
        return;
      }

      this._animateIn();
    },
    transitionOut: function transitionOut() {
      if (this.isElementHidden()) {
        return;
      }

      if (this.isAnimatingOut()) {
        return;
      }

      this._animateOut();
    },
    isAnimatingIn: function isAnimatingIn() {
      var scope = this._scope();

      if (scope == null || scope.length === 0) {
        return false;
      }

      return scope.hasClass('visible') && scope.hasClass('animating') && scope.hasClass('in');
    },
    isAnimatingOut: function isAnimatingOut() {
      var scope = this._scope();

      if (scope == null || scope.length === 0) {
        return false;
      }

      return scope.hasClass('visible') && scope.hasClass('animating') && scope.hasClass('out');
    },
    isElementVisible: function isElementVisible() {
      var scope = this._scope();

      if (scope == null || scope.length === 0) {
        return false;
      }

      return scope.hasClass('visible') && scope.hasClass('active');
    },
    isElementHidden: function isElementHidden() {
      var scope = this._scope();

      if (scope == null || scope.length === 0) {
        return false;
      }

      return scope.hasClass('hidden');
    },
    // private methods
    _scope: function _scope() {
      var scope = this.get('transitionScope');

      if (Ember.isPresent(scope)) {
        if (this.element != null) {
          return (0, _jquery.default)(scope, this.element);
        }

        return (0, _jquery.default)(scope);
      }

      return (0, _jquery.default)(this.element);
    },
    _animateIn: function _animateIn() {
      this._addBaseProperties();

      var scope = this._scope();

      if (scope == null || scope.length === 0) {
        return;
      }

      scope.removeClass('hidden out');

      var animationMode = this._animationMode();

      scope.addClass("visible animating ".concat(animationMode, " in"));
      Ember.run.later(this, this._animatedIn, this._animationDuration());
    },
    _animatedIn: function _animatedIn() {
      if (this.get('isDestroyed') || this.get('isDestroying')) {
        return;
      } // Check we are still animating in


      if (this.isElementHidden()) {
        return;
      }

      if (this.isAnimatingOut()) {
        return;
      }

      if (!this.isAnimatingIn()) {
        return;
      } // We are still animating, switch to visible


      this._show();
    },
    _show: function _show() {
      var scope = this._scope();

      if (scope == null || scope.length === 0) {
        return;
      }

      var animationMode = this._animationMode();

      scope.addClass('visible active');
      scope.removeClass("animating ".concat(animationMode, " in"));
      this.shown();
    },
    // Shown event
    shown: function shown() {},
    _animateOut: function _animateOut() {
      this._addBaseProperties();

      var scope = this._scope();

      if (scope == null || scope.length === 0) {
        return;
      }

      scope.removeClass('in');

      var animationMode = this._animationMode();

      scope.addClass("visible animating ".concat(animationMode, " out"));
      Ember.run.later(this, this._animatedOut, this._animationDuration());
    },
    _animatedOut: function _animatedOut() {
      if (this.get('isDestroyed') || this.get('isDestroying')) {
        return;
      } // Check we are still animating out


      if (this.isAnimatingIn()) {
        return;
      }

      if (!this.isAnimatingOut()) {
        return;
      }

      if (this.isElementHidden()) {
        return;
      } // We are still animating, switch to visible


      this._hide();
    },
    _hide: function _hide() {
      var scope = this._scope();

      if (scope == null || scope.length === 0) {
        return;
      }

      var animationMode = this._animationMode();

      scope.addClass('hidden');
      scope.removeClass("visible active animating ".concat(animationMode, " out"));
      this.hidden();
    },
    // Hidden event
    hidden: function hidden() {},
    _addBaseProperties: function _addBaseProperties() {
      var scope = this._scope();

      if (scope == null || scope.length === 0) {
        return;
      }

      var animationDuration = this._animationDuration();

      var style = scope.prop('style');
      var formatted = "".concat(animationDuration, "ms");

      if (Ember.isBlank(style.animationDuration) || style.animationDuration !== formatted) {
        scope.css('animation-duration', formatted);
      }

      scope.addClass('transition');
    },
    _animationDuration: function _animationDuration() {
      var animationDuration = this.get('transitionDuration');

      if (Ember.isBlank(animationDuration)) {
        return 500;
      }

      animationDuration = parseInt(animationDuration, 10);

      if (isNaN(animationDuration)) {
        return 500;
      }

      return animationDuration;
    },
    _animationMode: function _animationMode() {
      var animationMode = this.get('transitionMode');

      if (Ember.isBlank(animationMode)) {
        return 'fade';
      }

      return animationMode;
    }
  });

  _exports.default = _default;
});