define("survey/mixins/models/product/mapping", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    // each helper
    child_image_src: Ember.computed.readOnly('child.image_src')
  });

  _exports.default = _default;
});