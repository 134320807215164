define("survey/pods/project/clients/serious-poulp/route", ["exports", "survey/utils/client-pages"], function (_exports, _clientPages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    // Move to mixin if we get more than one client
    beforeModel: function beforeModel(transition) {
      var _this = this;

      return Ember.RSVP.resolve(this._super.apply(this, arguments)).then(function () {
        // If we are already transitioning to something else, return
        if (transition != null && transition.isAborted) {
          return;
        }

        var order = _this.controllerFor('project').get('order');

        if (order == null) {
          return;
        }

        var next_step = 'rewards';

        if (Ember.isPresent(order.get('furthest_step'))) {
          next_step = order.get('furthest_step');
        }

        next_step = 'project.' + next_step; // Has to be serious poulp project id
        // We could retrict to company or something in the future

        if (order.get('project.id').toString() !== "100437") {
          _this.replaceWith(next_step);

          return;
        } // Ensure feature flag is on


        if (!(0, _clientPages.has_client_page)(order.get('project'))) {
          _this.replaceWith(next_step);

          return;
        }
      });
    }
  });

  _exports.default = _default;
});