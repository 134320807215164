define("survey/pods/confirm/error/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "X+TDdBrG",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[\"Loading Error\"],null],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"ui header\"],[8],[0,\"\\n  Oh no, there was an error while trying to load your information.\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"additional\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui small header\"],[8],[0,\"\\n    In order to attempt to fix this error, please try the following:\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui bulleted list\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"item\"],[8],[0,\"Ensure you click the link \\\"Click for Survey\\\" in your project reward email\"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"item\"],[8],[0,\"If \\\"Click for Survey\\\" isn't working, copy the full text below \\\"Use this link to view and manage your pledge\\\" and paste it into your browsers url bar.\"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"item\"],[8],[0,\"Avoid using a private browsing session\"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"item\"],[8],[0,\"If you are using Internet Explorer, ensure it is Internet Explorer 11 or above\"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"item\"],[8],[0,\"Try opening the link on a desktop computer. Not all, but some mobile browsers have had issues\"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"item\"],[8],[0,\"Ensure your browser is updated to the latest version\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"ui small header\"],[8],[0,\"\\n    We are sorry for any inconvenience this has caused.\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "survey/pods/confirm/error/template.hbs"
    }
  });

  _exports.default = _default;
});