define("survey/components/address-dropdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lkcJu7ZW",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[7,\"select\",false],[12,\"class\",\"ui dropdown countries\"],[12,\"autocomplete\",\"off\"],[3,\"on\",[\"change\",[28,\"action\",[[23,0,[]],\"changed\"],null]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"loading\"]]],null,{\"statements\":[[0,\"    \"],[7,\"option\",true],[10,\"selected\",\"selected\"],[10,\"disabled\",\"disabled\"],[8],[0,\"Loading Countries . . .\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"option\",true],[10,\"selected\",\"selected\"],[10,\"disabled\",\"disabled\"],[8],[0,\"Select a Country\"],[9],[0,\"\\n\"],[4,\"each\",[[24,[\"countries\"]]],null,{\"statements\":[[0,\"      \"],[7,\"option\",true],[11,\"value\",[23,1,[\"iso3\"]]],[11,\"selected\",[28,\"eq\",[[23,1,[\"iso3\"]],[24,[\"country\",\"iso3\"]]],null]],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]}],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "survey/components/address-dropdown/template.hbs"
    }
  });

  _exports.default = _default;
});