define("survey/pods/not-found/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2P9+mHa2",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[\"Not Found\"],null],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"ui header\"],[8],[0,\"\\n  Oh no, we are unable to find the url you are looking for.\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "survey/pods/not-found/template.hbs"
    }
  });

  _exports.default = _default;
});