define("survey/pods/project/catchall/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    redirect: function redirect() {
      var order = this.controllerFor('project').get('order');

      if (Ember.isBlank(order)) {
        this.replaceWith('project.order-not-found');
        return;
      }

      if (Ember.isPresent(order.get('approved_on'))) {
        this.replaceWith('project.review');
        return;
      }

      this.replaceWith('project.rewards');
    }
  });

  _exports.default = _default;
});