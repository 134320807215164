define("survey/mixins/determine-error", ["exports", "survey/utils/check-error"], function (_exports, _checkError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Mixin.create({
    get_order: function get_order() {
      if (Ember.isPresent(this.get('order.id'))) {
        return this.get('order');
      }

      if (typeof this.controllerFor === "function") {
        var projectController = this.controllerFor('project');

        if (Ember.isPresent(projectController) && Ember.isPresent(projectController.get('order.id'))) {
          return projectController.get('order');
        }
      }

      return null;
    },
    get_project: function get_project() {
      var order = this.get_order();

      if (Ember.isPresent(order)) {
        return order.get('project');
      }

      if (typeof this.controllerFor === "function") {
        var projectController = this.controllerFor('project');

        if (Ember.isPresent(projectController) && Ember.isPresent(projectController.get('project.id'))) {
          return projectController.get('project');
        }
      }

      if (typeof this.modelFor === "function") {
        var project = this.modelFor('project');
        return project;
      }

      return null;
    },
    actions: {
      // This method specifically checks that a validation message doesn't start with ":", if it does, it calls determine error
      check_error: function check_error(error) {
        if (Ember.isPresent(error)) {
          if (Ember.isPresent(error.errors)) {
            for (var j = 0; j < error.errors.length; j++) {
              var sub_error = error.errors[j];

              if (Ember.isPresent(sub_error.meta) && Ember.isPresent(sub_error.meta.code) && sub_error.meta.code.indexOf(":") === 0) {
                this.send('determine_error', error);
                return;
              }

              if (Ember.isPresent(sub_error.title) && sub_error.title.indexOf(":") === 0) {
                this.send('determine_error', error);
                return;
              }
            }
          }
        }
      },
      // this handles and displays all error messages for project
      determine_error: function determine_error(error, options) {
        if (!options) {
          options = {};
        }

        options.passed_error = error;
        options.project = this.get_project();
        options.order = this.get_order();

        if (Ember.isBlank(error)) {
          // something happened, we don't know what
          this.send('open_modal', 'project/errors/saving-modal', options);
          return;
        } // once ds-extended-errors is out, use that instead of this hack


        if (Ember.isPresent(error.message) && error.message.indexOf('401') >= 0) {
          this.send('authorizationFailed');
          return;
        }

        if ((0, _checkError.default)(error, ":invalid_selections")) {
          this.send('open_modal', 'project/errors/order-invalid-modal', options);
          return;
        } // configuration full


        if ((0, _checkError.default)(error, ":configuration_unavailable")) {
          this.send('open_modal', 'project/errors/configuration-unavailable-modal', options);
          return;
        } // unable to update customer


        if ((0, _checkError.default)(error, ":unable_to_update_order")) {
          this.send('open_modal', 'project/errors/unable-to-update-order-modal', options);
          return;
        }

        if ((0, _checkError.default)(error, ":unable_to_update_address")) {
          // using same modal as update customer for time. update later
          this.send('open_modal', 'project/errors/unable-to-update-order-modal', options);
          return;
        }

        if ((0, _checkError.default)(error, ":invalid_balance")) {
          this.send('open_modal', 'project/errors/transaction-invalid-modal', options);
          return;
        } // the order is over the max amount per customer limit


        if ((0, _checkError.default)(error, ":overmax_products")) {
          var overage = error.errors[0].meta.selections;
          options.overage = Ember.A();

          if (Ember.isPresent(overage)) {
            var _options$overage;

            (_options$overage = options.overage).push.apply(_options$overage, _toConsumableArray(overage));
          }

          this.send('open_modal', 'project/errors/over-max-modal', options);
          return;
        } // the order is over the total product/addon limit


        if ((0, _checkError.default)(error, ":overlimit_products")) {
          var _overage = error.errors[0].meta.selections;
          options.overage = Ember.A();

          if (Ember.isPresent(_overage)) {
            var _options$overage2;

            (_options$overage2 = options.overage).push.apply(_options$overage2, _toConsumableArray(_overage));
          }

          this.send('open_modal', 'project/errors/over-limit-modal', options);
          return;
        }

        if ((0, _checkError.default)(error, ":invalid_country")) {
          this.send('open_modal', 'project/errors/country-invalid-modal', options);
          return;
        }

        if ((0, _checkError.default)(error, ":invalid_address")) {
          this.send('open_modal', 'project/errors/address-invalid-modal', options);
          return;
        } // default to generic error, no information, just something happened


        return this.send('open_modal', 'project/errors/saving-modal', options);
      },
      authorizationFailed: function authorizationFailed() {
        Ember.run.scheduleOnce('afterRender', this, this.show_not_logged_in);
        return false;
      }
    },
    show_not_logged_in: function show_not_logged_in() {
      return this.send('open_modal', 'project/errors/not-logged-in-modal', {
        project: this.get_project()
      });
    }
  });

  _exports.default = _default;
});