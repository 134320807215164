define("survey/mixins/restrict-by-condition", ["exports", "@sentry/browser"], function (_exports, Sentry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    beforeModel: function beforeModel(transition) {
      var _this = this;

      return Ember.RSVP.resolve(this._super.apply(this, arguments)).then(function () {
        // If we are already transitioning to something else, return
        if (transition != null && transition.isAborted) {
          return;
        }

        var order = _this.controllerFor('project').get('order');

        if (order == null) {
          return;
        } // Test for any external lines that have no mapped products.
        // If no products, user cannot complete survey,


        var transitionToSetup = false;
        order.get('external_lines').forEach(function (line) {
          if (line.get('product_bundle.has_enabled_mappings') === false) {
            Sentry.captureMessage("Bundle ".concat(line.get('product_bundle.id'), " has no products, preventing customers from completing their survey."));
            transitionToSetup = true;
          }
        });

        if (transitionToSetup) {
          _this.transitionTo('project.setup');
        }
      });
    }
  });

  _exports.default = _default;
});