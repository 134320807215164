define("survey/pods/project/clients/serious-poulp/wave/route", ["exports", "survey/mixins/scroll-route", "shared/utils/monetize"], function (_exports, _scrollRoute, _monetize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Shipping = Ember.Object.extend({
    balance: (0, _monetize.default)('total_cents', 'currency'),
    shipping_costs: (0, _monetize.default)('shipping_cents', 'currency')
  });

  var _default = Ember.Route.extend(_scrollRoute.default, {
    ajax: Ember.inject.service(),
    model: function model() {
      var project = this.controllerFor('project').get('project');
      var order = this.controllerFor('project').get('order');
      return new Ember.RSVP.hash({
        project: project,
        order: order,
        shipping: this.get('ajax').request("".concat(project.get('settings.api.wave-shipping'), "/").concat(order.get('id')))
      });
    },
    afterModel: function afterModel(model) {
      if (model.shipping.none != null) {
        var controller = this.controllerFor('project.clients.serious-poulp.wave');
        this.setupController(controller, model);
        return controller.get('selectTask').perform('none', false);
      }

      var shipping = {};

      if (model.shipping.single != null) {
        model.shipping.single.currency = model.project.get('currency');
        shipping.single = Shipping.create(model.shipping.single);
      }

      if (model.shipping.multiple != null) {
        model.shipping.multiple.currency = model.project.get('currency');
        shipping.multiple = Shipping.create(model.shipping.multiple);
      }

      model.shipping = shipping;
    },
    redirect: function redirect(model) {
      if (model.shipping.none != null) {
        this.transitionTo('project.confirm');
      }
    },
    resetController: function resetController(controller, isExiting) {
      this._super.apply(this, arguments);

      if (isExiting) {
        return controller.set('error_reason', null);
      }
    },
    renderTemplate: function renderTemplate() {
      this.render('shared/hero', {
        into: 'application'
      });
      this.render('project', {
        into: 'shared/hero',
        controller: 'project'
      });
      this.render('project/clients/serious-poulp/wave/menu', {
        into: 'shared/hero',
        outlet: 'hero'
      });
      this.render('project/clients/serious-poulp/wave', {
        into: 'project'
      });
    }
  });

  _exports.default = _default;
});