define("survey/models/zone-vat", ["exports", "ember-data", "ember-data-model-fragments/fragment", "ember-cp-validations"], function (_exports, _emberData, _fragment, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    type: {
      description: "Type",
      validators: [(0, _emberCpValidations.validator)('inclusion', {
        in: ['vat']
      })]
    },
    prices: {
      description: "Prices",
      validators: [(0, _emberCpValidations.validator)('presence')]
    }
  });

  var _default = _fragment.default.extend(Validations, {
    type: _emberData.default.attr('string'),
    prices: _emberData.default.attr('object'),
    parent: Ember.computed.readOnly('_internalModel._recordData._owner'),
    project: Ember.computed.readOnly('parent.project')
  });

  _exports.default = _default;
});