define("survey/mixins/scroll-route", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Currently only on Project route
  // Routes with a nav bar (Rewards, Extras, Shipping, Confirm) scroll to nav bar
  // Routes without nav bar (Address, Review) scroll to top of their page
  var _default = Ember.Mixin.create({
    activate: function activate() {
      this._super.apply(this, arguments);

      return Ember.run.scheduleOnce('afterRender', this, this.scroll_to_top);
    },
    scroll_to_top: function scroll_to_top() {
      if (this.get('isDestroyed') || this.get('isDestroying')) {
        return;
      }

      var total_scroll = (0, _jquery.default)(window).scrollTop();
      var target = (0, _jquery.default)("[data-marker=scroll-top]");

      if (!target || target.length === 0) {
        return;
      } // originally I tried animated scroll, felt unnatural.
      // Just jumping up feels more like expected web navigation


      var target_offset = target.offset().top - 50;

      if (total_scroll > target_offset) {
        return (0, _jquery.default)('html, body').scrollTop(target_offset);
      }
    }
  });

  _exports.default = _default;
});