define("survey/pods/project/review/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    customer: null,
    order: null,
    confirmed_transactions: Ember.computed('order.transactions.[]', function () {
      return this.get('order.transactions').filter(function (transaction) {
        return transaction.get('confirmed') && !transaction.get('is_refund');
      }).sortBy('actual_created_at');
    })
  });

  _exports.default = _default;
});