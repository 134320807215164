define("survey/components/ui-error-messages/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    // Only show non-symbol error messages
    errors: Ember.computed.filter('model.validations.errors.@each.message', function (error) {
      if (error.get('message').startsWith(":")) {
        return false;
      }

      return true;
    }),
    actions: {
      friendly: function friendly(text) {
        text = (text || "").trim();
        return text.replace(/_/g, ' ').replace(/\w\S*/g, function (text) {
          return text.charAt(0).toUpperCase() + text.substr(1).toLowerCase();
        });
      }
    }
  });

  _exports.default = _default;
});