define("survey/pods/project/components/wave-shipping-extension/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SMtVKuqj",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"can_access\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"row wave-shipping-extension component title\"],[8],[0,\"\\n    \"],[7,\"div\",false],[12,\"class\",\"eleven wide column total expandable\"],[3,\"action\",[[23,0,[]],\"toggle\",\"wave_shipping\"]],[8],[0,\"\\n      Shipping Selection \"],[7,\"i\",true],[11,\"class\",[29,[\"icon angle \",[28,\"if\",[[24,[\"wave_shipping_expanded\"]],\"up\",\"down\"],null]]]],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"five wide column right aligned price\"],[8],[0,\"\\n      \"],[1,[28,\"capitalize\",[[24,[\"order\",\"wave_shipping_selection\"]]],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"wave_shipping_expanded\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"row wave-shipping-extension component description\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"sixteen wide column\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"ui list breakdown\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[\"single\",[24,[\"order\",\"wave_shipping_selection\"]]],null]],null,{\"statements\":[[0,\"            \"],[7,\"div\",true],[10,\"class\",\"item\"],[8],[0,\"- Ship all at once (in a single shipment)\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[7,\"div\",true],[10,\"class\",\"item\"],[8],[0,\"- Ship as soon as possible (\"],[1,[22,\"ship_text\"],false],[0,\" shipments)\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "survey/pods/project/components/wave-shipping-extension/template.hbs"
    }
  });

  _exports.default = _default;
});