define("survey/pods/project/rewards/components/product-information/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0rxv/tpi",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"sized-image\",null,[[\"image_path\",\"gallery_image_paths\",\"title\",\"width\",\"height\",\"mobile_width\",\"mobile_height\"],[[24,[\"product\",\"image_path\"]],[24,[\"product\",\"gallery_image_paths\"]],[24,[\"product\",\"name\"]],234,234,234,234]]],false],[0,\"\\n\"],[4,\"show-more-text\",null,[[\"class\",\"lines\"],[\"content\",8]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"or\",[[24,[\"has_quantity\"]],[28,\"can-access-feature\",[[24,[\"product\",\"project\"]],\"always-show-quantity\"],null]],null]],null,{\"statements\":[[0,\"      \"],[1,[28,\"pluralize\",[[24,[\"quantity\"]],[24,[\"product\",\"name\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[24,[\"product\",\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"description\"],[8],[0,\"\\n    \"],[1,[24,[\"product\",\"description\"]],true],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "survey/pods/project/rewards/components/product-information/template.hbs"
    }
  });

  _exports.default = _default;
});