define("survey/pods/project/components/address-workflow/component", ["exports", "survey/mixins/scroll-route"], function (_exports, _scrollRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_scrollRoute.default, {
    step: 'update',
    setFromHash: function setFromHash(hash) {
      this.set('current_address', hash.current_address);
      this.set('updated_address', hash.updated_address);
      this.set('unknown_errors', hash.unknown_errors);
      this.set('action', hash.action);
    },
    actions: {
      address_compare: function address_compare(hash) {
        this.setFromHash(hash);
        this.set('step', 'compare');
        this.activate(); // Scroll route
      },
      address_error: function address_error(hash) {
        this.setFromHash(hash);
        this.set('step', 'error');
        this.activate(); // Scroll route
      },
      edit: function edit() {
        this.set('step', 'update');
        this.activate(); // Scroll route
      }
    }
  });

  _exports.default = _default;
});