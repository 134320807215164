define("survey/services/customer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ServiceProxy = Ember.ObjectProxy.extend();
  ServiceProxy.reopenClass({
    isServiceFactory: true
  });

  var _default = ServiceProxy.extend({});

  _exports.default = _default;
});