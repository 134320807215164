define("survey/svgs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "svgs": {
      "icon-date": "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M14.833 2.183c-.083-.016-1.033-.2-2.566-.366v-.75C12.267.483 11.783 0 11.2 0s-1.067.483-1.067 1.067v2.25c0 .45.284.716.684.883a1.676 1.676 0 0 1-.417.067c-.733 0-1.333-.6-1.333-1.334V1.617C8.717 1.6 8.367 1.6 8 1.6c-.75 0-1.467.017-2.133.05v-.583C5.867.483 5.383 0 4.8 0S3.733.483 3.733 1.067v2.25c0 .45.284.716.684.883-.134.033-.267.067-.417.067-.733 0-1.333-.6-1.333-1.334V1.95c-.9.117-1.45.233-1.5.233a.803.803 0 0 0-.634.784v11.65c0 .383.267.7.634.783.116.017 2.816.6 6.833.6s6.717-.583 6.833-.6a.803.803 0 0 0 .634-.783V2.967c0-.384-.267-.7-.634-.784zM6.583 13.05c-.3-.4-2.7-3.95-2.7-3.95-.133-.183-.116-.433.034-.567.333-.3.816-.65 1.2-.866.183-.1.416-.034.55.15L7.383 10.4 10.4 5.833a.406.406 0 0 1 .533-.15c.4.217 1.084.584 1.417.884.15.133.167.366.033.55 0 0-3.883 5.516-4.183 5.933-.417.567-1.2.6-1.617 0z\" fill=\"#666\" fill-rule=\"evenodd\"/></svg>",
      "icon-kickstarter": "<svg width=\"21\" height=\"21\" viewBox=\"0 0 21 21\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M10.5 21C16.299 21 21 16.299 21 10.5S16.299 0 10.5 0 0 4.701 0 10.5 4.701 21 10.5 21zM9.561 9.002L11.29 6.5c.327-.471.748-.707 1.266-.707.421 0 .786.15 1.095.449.308.299.462.657.462 1.073 0 .309-.082.58-.245.816l-1.558 2.263 1.905 2.412c.19.24.286.52.286.842 0 .426-.15.792-.45 1.098-.298.306-.661.459-1.088.459-.467 0-.822-.152-1.068-.456l-2.333-2.908v1.604c0 .457-.08.813-.238 1.066-.29.463-.712.694-1.265.694-.504 0-.894-.17-1.17-.51-.259-.313-.388-.727-.388-1.243V7.506c0-.488.131-.892.395-1.209.276-.335.657-.503 1.142-.503.463 0 .848.168 1.157.503.172.186.281.374.326.564.027.118.041.338.041.66V9z\" fill=\"#666\" fill-rule=\"evenodd\"/></svg>",
      "icon-link": "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M5.55 16c1.383 0 2.7-.533 3.583-1.433l5.367-5.35a5.216 5.216 0 0 0 1.5-3.8c-.017-1.05-.4-2.584-1.917-4.034C13.067.417 11.867 0 10.667 0 9.65 0 8.683.267 7.95.717c-.5.316-.65.966-.35 1.466.317.5.967.667 1.467.35.6-.366 2.066-.933 3.566.434.784.716 1.217 1.583 1.234 2.5.016.833-.317 1.683-.884 2.25l-5.35 5.35c-.683.683-2.65 1.533-4.383-.2-.917-.917-1.133-1.867-1.133-2.5 0-.95.416-1.667.766-2.034L5.617 5.65c.5-.5 1.4-.467 1.916.067.3.3.517.716.5 1.133 0 .233-.066.567-.316.8L6.183 9.083A1.092 1.092 0 0 0 6.15 10.6c.4.433 1.083.45 1.5.033l1.55-1.45c.617-.616.933-1.433.933-2.333 0-.983-.416-1.917-1.116-2.617A3.625 3.625 0 0 0 6.483 3.2c-.883 0-1.766.317-2.4.967l-2.7 2.65C.517 7.683 0 9.017 0 10.367c0 1.033.3 2.583 1.75 4.016C2.883 15.517 4.25 16 5.55 16z\" fill=\"#666\" fill-rule=\"evenodd\"/></svg>",
      "icon-minus": "<svg height=\"16\" viewBox=\"0 0 16 16\" width=\"16\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M3.84 8.32h7.68V7.04H3.84v1.28zM7.68 0C3.445 0 0 3.445 0 7.68c0 4.235 3.445 7.68 7.68 7.68 4.235 0 7.68-3.445 7.68-7.68C15.36 3.445 11.915 0 7.68 0zm0 14.08a6.407 6.407 0 0 1-6.4-6.4c0-3.529 2.871-6.4 6.4-6.4 3.529 0 6.4 2.871 6.4 6.4 0 3.529-2.871 6.4-6.4 6.4z\" fill=\"#929292\" fill-rule=\"evenodd\"/></svg>",
      "icon-plus": "<svg height=\"16\" viewBox=\"0 0 16 16\" width=\"16\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M8.32 3.84H7.04v3.2h-3.2v1.28h3.2v3.2h1.28v-3.2h3.2V7.04h-3.2v-3.2zM7.68 0C3.445 0 0 3.445 0 7.68c0 4.235 3.445 7.68 7.68 7.68 4.235 0 7.68-3.445 7.68-7.68C15.36 3.445 11.915 0 7.68 0zm0 14.08a6.407 6.407 0 0 1-6.4-6.4c0-3.529 2.871-6.4 6.4-6.4 3.529 0 6.4 2.871 6.4 6.4 0 3.529-2.871 6.4-6.4 6.4z\" fill=\"#929292\" fill-rule=\"evenodd\"/></svg>",
      "icon-product": "<svg width=\"90\" height=\"103\" viewBox=\"0 0 90 103\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M51.429 74.033V103H83.57A6.433 6.433 0 0 0 90 96.562v-22.53H51.429zm-12.858 0H0v22.53A6.433 6.433 0 0 0 6.429 103H38.57V74.033zM0 61.158V38.625a6.433 6.433 0 0 1 6.429-6.438h17.873c-.862-.333-1.667-.64-2.364-.903-3.117-1.184-6.063-2.302-8.398-3.417-2.128-1.017-7.111-3.396-7.111-8.555 0-1.256 0-5.08 7.106-12.195C20.641 0 24.46 0 25.715 0c3.344 0 6.388 1.695 15.587 11.744 1.3 1.42 2.56 2.833 3.698 4.13a267.567 267.567 0 0 1 3.698-4.13C57.897 1.695 60.941 0 64.286 0c1.255 0 5.073 0 12.179 7.117 7.106 7.115 7.106 10.939 7.106 12.195 0 5.16-4.983 7.538-7.111 8.555-2.335 1.115-5.281 2.233-8.4 3.417-.693.264-1.501.57-2.362.904H83.57A6.433 6.433 0 0 1 90 38.624v22.533H51.429v-28.97H38.57v28.97H0z\" fill=\"#E7E7E7\" fill-rule=\"evenodd\"/></svg>",
      "icon-radio": "<svg width=\"20\" height=\"20\" viewBox=\"0 0 20 20\" xmlns=\"http://www.w3.org/2000/svg\"><g fill=\"none\" fill-rule=\"evenodd\"><circle stroke=\"#88C057\" stroke-width=\"2\" fill=\"#FFF\" cx=\"10\" cy=\"10\" r=\"10\"/><circle fill=\"#88C057\" cx=\"10\" cy=\"10\" r=\"6\"/></g></svg>"
    }
  };
  _exports.default = _default;
});