define("survey/pods/project/clients/route", ["exports", "survey/mixins/restrict-by-condition", "survey/mixins/restrict-by-step", "survey/mixins/restrict-by-status"], function (_exports, _restrictByCondition, _restrictByStep, _restrictByStatus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Status is first priority then step
  var _default = Ember.Route.extend(_restrictByCondition.default, (0, _restrictByStatus.default)(['incomplete']), (0, _restrictByStep.default)('shipping'), {});

  _exports.default = _default;
});