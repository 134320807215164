define("survey/mixins/models/order/transaction", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    ajax: Ember.inject.service(),
    update_status: function update_status() {
      return this.get('ajax').postModel(this, 'update_status');
    }
  });

  _exports.default = _default;
});