define("survey/mirage/factories/project", ["exports", "shared/mirage/factories/project", "shared/mirage/translations"], function (_exports, _project, _translations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _project.default.extend({
    purpose: 'pledge_management',
    currency: 'USD',
    afterCreate: function afterCreate(project, server) {
      server.create('project-translation', {
        projectId: project.id,
        locale: 'en',
        phrase: _translations.default.purpose[project.purpose]['phrase'],
        lines: _translations.default.purpose[project.purpose]['lines'],
        source: _translations.default.purpose[project.purpose]['source'],
        checkout: {}
      });
    }
  });

  _exports.default = _default;
});