define("survey/helpers/can-access-feature", ["exports", "shared/helpers/can-access-feature"], function (_exports, _canAccessFeature) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "canAccessFeature", {
    enumerable: true,
    get: function get() {
      return _canAccessFeature.canAccessFeature;
    }
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _canAccessFeature.default;
    }
  });
});