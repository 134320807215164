define("survey/models/project-configuration", ["exports", "survey/utils/generated-model"], function (_exports, _generatedModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _generatedModel.default)('project-configuration').extend({
    // Status tracking
    in_setup: Ember.computed.equal('status', 'setup'),
    is_live: Ember.computed.equal('status', 'live'),
    is_complete: Ember.computed.equal('status', 'complete'),
    // Check if we need these properties below
    available_orders: Ember.computed('stats.total_orders', 'product_reward.inventory_total_quantity', function () {
      if (this.get('product_reward.inventory_total_quantity') == null) {
        return null;
      }

      return this.get('product_reward.inventory_total_quantity') - this.get('stats.total_orders');
    }),
    is_all_gone: Ember.computed('stats.total_orders', 'product_reward.inventory_total_quantity', function () {
      // We need to make sure this takes account only what is
      // on the Kickstarter page and not from the CSV. Since
      // the CSV will potentially import less customers then
      // the total and enable some "early-bird" configurations
      if (this.get('product_reward.inventory_total_quantity') == null) {
        return false;
      }

      return this.get('stats.total_orders') >= this.get('product_reward.inventory_total_quantity');
    })
  });

  _exports.default = _default;
});