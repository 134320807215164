define("survey/pods/project/wave/route", ["exports", "survey/mixins/restrict-by-condition", "survey/mixins/restrict-by-step", "survey/mixins/restrict-by-status", "survey/utils/client-pages", "shared/utils/monetize"], function (_exports, _restrictByCondition, _restrictByStep, _restrictByStatus, _clientPages, _monetize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.Shipping = void 0;
  var Shipping = Ember.Object.extend({
    balance: (0, _monetize.default)('total_cents', 'currency'),
    shipping_costs: (0, _monetize.default)('shipping_cents', 'currency')
  }); // Status is first priority then step

  _exports.Shipping = Shipping;

  var _default = Ember.Route.extend(_restrictByCondition.default, (0, _restrictByStatus.default)(['incomplete']), (0, _restrictByStep.default)('shipping'), {
    beforeModel: function beforeModel(transition) {
      var _this = this;

      return Ember.RSVP.resolve(this._super.apply(this, arguments)).then(function () {
        // If we are already transitioning to something else, return
        if (transition != null && transition.isAborted) {
          return;
        }

        var order = _this.controllerFor('project').get('order');

        if (order == null) {
          return;
        }

        var next_step = 'rewards';

        if (Ember.isPresent(order.get('furthest_step'))) {
          next_step = order.get('furthest_step');
        }

        next_step = 'project.' + next_step; // Ensure feature flag is on

        if (!(0, _clientPages.has_feature_flag_page)(order.get('project'))) {
          _this.replaceWith(next_step);

          return;
        }
      });
    },
    model: function model() {
      var project = this.controllerFor('project').get('project');
      var order = this.controllerFor('project').get('order');
      order = order.get('content') || order;
      return new Ember.RSVP.hash({
        project: project,
        order: order,
        shipping: order.wave_selection()
      });
    },
    afterModel: function afterModel(model) {
      if (model.shipping.autocompleted === true) {
        // We need to reload lines, selections for updated information
        return this.store.findRecord('order', model.order.get('id'), {
          include: ['lines', 'selections'].join(',')
        });
      } else {
        // Didn't auto complete, so get the two models
        model.shipping.content.single = Shipping.create(model.shipping.content.single);
        model.shipping.content.split = Shipping.create(model.shipping.content.split);
      }
    },
    redirect: function redirect(model) {
      if (model.shipping.autocompleted === true) {
        this.transitionTo('project.confirm');
      }
    },
    resetController: function resetController(controller, isExiting) {
      this._super.apply(this, arguments);

      if (isExiting) {
        return controller.set('error_reason', null);
      }
    }
  });

  _exports.default = _default;
});