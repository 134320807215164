define("survey/helpers/a-or-an", ["exports", "shared/helpers/a-or-an"], function (_exports, _aOrAn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "aOrAn", {
    enumerable: true,
    get: function get() {
      return _aOrAn.aOrAn;
    }
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _aOrAn.default;
    }
  });
});