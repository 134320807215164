define("survey/mixins/models/product/variant-value", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    // need this for the variations dialog, not sure how else to do it
    checked: (0, _model.attr)('boolean', {
      defaultValue: false
    })
  });

  _exports.default = _default;
});