define("survey/pods/project/extras/components/mapping-section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8t4GISjc",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui header\"],[8],[0,\"\\n    \"],[1,[24,[\"product_mapping\",\"child\",\"name\"]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"product_mapping\",\"child\",\"description\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"sub header\"],[8],[0,\"\\n        \"],[1,[24,[\"product_mapping\",\"child\",\"description\"]],true],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "survey/pods/project/extras/components/mapping-section/template.hbs"
    }
  });

  _exports.default = _default;
});