define("survey/models/country", ["exports", "survey/utils/generated-model"], function (_exports, _generatedModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _generatedModel.default)('country').extend({
    has_states: Ember.computed('states.[]', function () {
      return this.get('states.length') > 0;
    })
  });

  _exports.default = _default;
});